import { FunctionComponent } from 'react';

import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { ScheduleLog } from '../../types/entities';

interface Props {
  events: ScheduleLog[];
}

const PositionScheduleLog: FunctionComponent<Props> = ({ events }: Props) => {
  const header = (
    <TableHead>
      <TableRow>
        <TableCell title="Time">Logged</TableCell>
        <TableCell align="right" title="PnL">
          PnL
        </TableCell>
        <TableCell title="Price">Message</TableCell>
      </TableRow>
    </TableHead>
  );

  const rows = events.map((x, i) => {
    const loggedDt = new Date(x.logged);
    const formattedLoggedDt = dayjs(loggedDt).format('MM/DD HH:mm A');

    let pnlColor = 'inherit';
    if (x.pnl > 0) {
      pnlColor = '#00a86b';
    } else if (x.pnl < 0) {
      pnlColor = '#e52b50';
    }

    const msgColor = x.isError ? '#e52b50' : 'inherit';
    return (
      <TableRow key={i}>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{formattedLoggedDt}</TableCell>
        <TableCell align="right" title="Elapsed" sx={{ color: pnlColor }}>
          ${x.pnl.toFixed(0)}
        </TableCell>
        <TableCell align="left" title="Message" sx={{ color: msgColor, whiteSpace: 'nowrap' }}>
          {x.message}
        </TableCell>
      </TableRow>
    );
  });

  if (events.length === 0) {
    return (
      <Box>
        <Typography variant="body2" component="div" sx={{ ml: 2, mb: 2 }}>
          No log records found..
        </Typography>
        <Divider />
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table size="small">
        {header}
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default PositionScheduleLog;
