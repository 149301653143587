import { FunctionComponent, useEffect } from 'react';

import { Box, Button, Card, TextField } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../../components/hooks/redux-hooks';
import MarginPanel from '../../../../components/panels/MarginPanel';
import Spinner from '../../../../components/ui/Spinner';
import { setOrderSize } from '../../../../store/order-underlying/reducer';
import { checkUnderlyingMarginAsync } from '../../../../store/order-underlying/service';
import { CheckMarginParams, ContractDetails } from '../../../../types/entities';

const MarginOverviewStep: FunctionComponent = () => {
  const orderUnderlyingSate = useAppSelector((gs) => gs.orderUnderlyingState);
  const contractDetails = orderUnderlyingSate.contractDetails as ContractDetails;
  const contract = contractDetails.contract;
  const { orderAction, orderSize, loading, margin } = orderUnderlyingSate;

  const dispatch = useAppDispatch();

  const handleChangeOrderSize = (size: number) => {
    if (size > 0) {
      dispatch(setOrderSize(size));
    }
  };

  const handleCheckUnderlyingMargin = () => {
    const optionMarginParams: CheckMarginParams = {
      conId: contract.conId,
      localSymbol: contract.localSymbol,
      secType: contract.secType,
      exchange: contract.exchange,
      currency: contract.currency,
      multiplier: contract.multiplier,
      orderAction,
      orderSize
    };
    dispatch(checkUnderlyingMarginAsync(optionMarginParams));
  };

  useEffect(() => {
    if (!margin) {
      handleCheckUnderlyingMargin();
    }
  }, []);

  return (
    <Card sx={{ mt: 3, p: 2 }}>
      <Spinner loading={loading} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ pr: 1, flexGrow: 1 }}>
            <TextField
              size="small"
              fullWidth
              label="Order Size"
              variant="outlined"
              type="number"
              value={orderSize}
              onChange={(e) => handleChangeOrderSize(Number(e.target.value))}
            />
          </Box>
          <Button onClick={() => handleCheckUnderlyingMargin()} variant="outlined" type="button">
            Check
          </Button>
        </Box>
        {margin && <MarginPanel symbol={contract.localSymbol} margin={margin} />}
      </Box>
    </Card>
  );
};

export default MarginOverviewStep;
