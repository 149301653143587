import { FunctionComponent } from 'react';

import { Box, Grid, Tooltip, Typography } from '@mui/material';

import { useAppSelector } from '../../../../../../components/hooks/redux-hooks';
import RaceTrack, { RaceDriver, RaceSetup } from '../../../../../../components/panels/RaceTrack';
import { Position } from '../../../../../../types/entities';
import { formatNum, setDecimalSpaces } from '../../../../../../utils/currency-utils';

import {
  getOptionLossPct,
  getOptionProfitPct,
  getRaceDuractionPct,
  getStopLossPct,
  getStrikeReflectedPrice,
  getOptionRaceDriver
} from './utils';

interface Props {
  position: Position;
}

const OptionPositionSummary: FunctionComponent<Props> = ({ position }: Props) => {
  const { underMarketPrice, strikePrice, orderAction, right } = position;
  const isSellOption = orderAction !== 'BUY';

  let stopPriceFormatted = '',
    limitPriceFormatted = '';
  if (isSellOption) {
    stopPriceFormatted = '' + formatNum(strikePrice);
  } else {
    limitPriceFormatted = '' + formatNum(strikePrice);
  }

  const durationPct = getRaceDuractionPct(position);

  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const optionOpenOrder = openOrderState.orders.find((x) => x.conId === position.conId);

  let stopLossPct: number | undefined;
  if (isSellOption) {
    if (optionOpenOrder) {
      stopLossPct = getStopLossPct(position, optionOpenOrder.triggerPrice);
    }
  }

  const raceSetup: RaceSetup = {
    durationPct,
    lossAreaPct: 50,
    stopLossPct
  };

  const optionDriver = getOptionRaceDriver(position);
  const drivers: RaceDriver[] = [];
  if (optionDriver) {
    drivers.push(optionDriver);
  }

  const lossPct = getOptionLossPct(position);
  const lossPctFormatted = lossPct ? `${setDecimalSpaces(lossPct, 0)}%` : '-';
  const profitPct = getOptionProfitPct(position);
  const profitPctFormatted = profitPct ? `${setDecimalSpaces(profitPct, 0)}%` : '-';

  let moneyStatus: JSX.Element | undefined;
  if (underMarketPrice && underMarketPrice !== 0) {
    const inTheMoney = (right === 'CALL' && underMarketPrice > strikePrice) || (right === 'PUT' && underMarketPrice < strikePrice);

    moneyStatus = (
      <Tooltip title={inTheMoney ? 'ITM' : 'OTM'} placement="right">
        <Typography variant="caption" sx={{ color: inTheMoney ? '#ffd700' : 'inherit' }}>
          {formatNum(underMarketPrice)}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Box width="100%" sx={{ p: 1 }}>
      <RaceTrack drivers={drivers} setup={raceSetup} />
      <Grid container width="100%" sx={{ mt: 1 }}>
        <Grid item xs={3}>
          <Typography variant="caption">{stopPriceFormatted}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption" sx={{ color: '#e52b50' }}>
            {lossPctFormatted}
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'center' }}>
          <Typography variant="caption">{moneyStatus}</Typography>
        </Grid>
        <Grid item xs={2} sx={{ textAlign: 'right' }}>
          <Typography variant="caption" sx={{ color: '#00a86b' }}>
            {profitPctFormatted}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <Typography variant="caption">{limitPriceFormatted}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OptionPositionSummary;
