import { Routes, Route, Navigate } from 'react-router-dom';

import OrderPageOld from './archive/OrderPage';
import BackOffice from './pages/BackOffice';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Logout from './pages/Logout';
import OrderPage from './pages/OrderPage';
import OrderSummaryPage from './pages/OrderSummaryPage';
import Settings from './pages/Settings';
import TradeHistoryPage from './pages/TradeHistoryPage/TradeHistoryPage';

export const getRoutes = (authorized: boolean) => {
  if (!authorized) {
    return (
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="*" element={<Navigate to="login" replace />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="/order-old" element={<OrderPageOld />}></Route>
      <Route path="/order" element={<OrderPage />}></Route>
      <Route path="/order-summary/:symbol" element={<OrderSummaryPage />}></Route>
      <Route path="/back-office" element={<BackOffice />}></Route>
      <Route path="/settings" element={<Settings />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/logout" element={<Logout />}></Route>
      <Route path="/trade-history" element={<TradeHistoryPage />}></Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
