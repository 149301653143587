import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { setFavoritesPanelCollapsed, setSecurityPanelCollapsed } from '../../../store/user-settings/reducer';
import { ContractDetails, FavoriteContract } from '../../../types/entities';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import AccordionWrapper from '../../ui/AccordionWrapper';

import ContractDetailsPanel from './ContractDetailsPanel';
import FavoritesPanel from './FavoritesPanel';

interface Props {
  selected: ContractDetails | undefined;
  onSelectionChanged: (selected: ContractDetails | undefined) => void;
}

const ContractDetailsPicker: FunctionComponent<Props> = ({ selected, onSelectionChanged }: Props) => {
  const dispatch = useAppDispatch();
  const { securityPanelCollapsed, favoritesPanelCollapsed } = useAppSelector((gs) => gs.userSettingsState);
  const toggleSecurityPanelCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setSecurityPanelCollapsed(!expanded));
  const toggleFavoritesPanelCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setFavoritesPanelCollapsed(!expanded));

  const handleFavoriteSelected = (fav: FavoriteContract | undefined) => {
    const result = fav ? fav.details : undefined;
    onSelectionChanged(result);
  };

  const handleContractSelected = (details: ContractDetails | undefined) => {
    onSelectionChanged(details);
  };

  return (
    <Box>
      <AccordionWrapper title="Contracts" collapsed={securityPanelCollapsed} onChange={toggleSecurityPanelCollapsed}>
        {!securityPanelCollapsed && <ContractDetailsPanel selected={selected} onSelectionChanged={handleContractSelected} />}
      </AccordionWrapper>
      <AccordionWrapper title="Favorites" collapsed={favoritesPanelCollapsed} onChange={toggleFavoritesPanelCollapsed} p={2}>
        <FavoritesPanel selected={selected} onFavoriteSelected={handleFavoriteSelected} />
      </AccordionWrapper>
    </Box>
  );
};

export default ContractDetailsPicker;
