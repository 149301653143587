import { FunctionComponent } from 'react';

import { Box, Button, Skeleton } from '@mui/material';

import { setSelectedCategory } from '../../../store/user-settings/reducer';
import { ContractDetails, FavoriteContract } from '../../../types/entities';
import { SecCatergory } from '../../../types/enums';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import useCategoryFavorites from '../../hooks/useCategoryFavorites';
import SecurityCategoryTabs from '../../ui/SecurityCategoryTabs';

interface Props {
  selected: ContractDetails | undefined;
  onFavoriteSelected: (fav: FavoriteContract | undefined) => void;
}

const FavoritesPanel: FunctionComponent<Props> = ({ selected, onFavoriteSelected }) => {
  const { loading } = useAppSelector((gs) => gs.favoriteContractState);
  const dispatch = useAppDispatch();
  const onCategorySelected = (cat: SecCatergory) => dispatch(setSelectedCategory(cat));
  const selectedConId = selected?.contract?.conId || -1;

  const handleCategoryTabClick = (cat: SecCatergory) => {
    onFavoriteSelected(undefined);
    onCategorySelected(cat);
  };

  const handleFavBtnClick = (fav: FavoriteContract) => {
    const clickedConId = fav.details.contract.conId;
    if (clickedConId === selectedConId) {
      onFavoriteSelected(undefined);
    } else {
      onFavoriteSelected(fav);
    }
  };

  const filteredFavorites = useCategoryFavorites();

  const favoriteItems = filteredFavorites.map((x, i) => {
    const favConId = x.details.contract.conId;
    const favLocalSymbol = x.details.contract.localSymbol;
    return (
      <Button
        key={i}
        onClick={() => handleFavBtnClick(x)}
        variant={favConId === selectedConId ? 'contained' : 'outlined'}
      >{`${x.securityName} - ${favLocalSymbol}`}</Button>
    );
  });

  return (
    <>
      <SecurityCategoryTabs onCategoryChanged={handleCategoryTabClick} />
      {loading ? (
        <Skeleton variant="rounded" animation="wave" height={80} />
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>{favoriteItems}</Box>
      )}
    </>
  );
};

export default FavoritesPanel;
