import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Card, Container, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../components/hooks/redux-hooks';
import ContractDetailsPicker from '../../components/panels/ContractDetailsPicker';
import SimpleWizardNav from '../../components/wizard/SimpleWizardNav';
import { setSelectedContractDetails } from '../../store/user-settings/reducer';
import { ContractDetails } from '../../types/entities';

import OptionOrderPanel from './OptionOrderPanel';
import OrderTabList from './OrderTabList';
import OrderUnderlyingPanel from './OrderUnderlyingPanel';

const OrderPage: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab') ?? '';
  const { selectedContractDetails } = useAppSelector((gs) => gs.userSettingsState);

  const handleSelectionChanged = (details: ContractDetails | undefined) => {
    dispatch(setSelectedContractDetails(details));
  };

  const handleGoToOrderSummaryPage = () => {
    navigate(`/order-summary/${selectedContractDetails?.contract.symbol}`);
  };

  const [wizardStep, setWizardStep] = useState(0);

  useEffect(() => {
    if (selectedContractDetails) {
      setWizardStep(1);
    }
  }, []);

  const selectContractStep = (
    <Container>
      <Card sx={{ my: 2 }}>
        <ContractDetailsPicker selected={selectedContractDetails} onSelectionChanged={handleSelectionChanged} />
      </Card>
      <SimpleWizardNav activeStep={0} steps={3} next={() => setWizardStep(1)} disableNext={!selectedContractDetails} />
    </Container>
  );

  const addOrderStep = useMemo(() => {
    return selectedContractDetails ? (
      <Container>
        {selectedTab === 'underlying' && <OrderUnderlyingPanel underlying={selectedContractDetails} />}
        {selectedTab !== 'underlying' && <OptionOrderPanel underlying={selectedContractDetails} />}
        <SimpleWizardNav activeStep={1} steps={3} previous={() => setWizardStep(0)} next={handleGoToOrderSummaryPage} />
      </Container>
    ) : undefined;
  }, [selectedTab, selectedContractDetails]);

  return (
    <div>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Place Order
        </Typography>
      </Container>
      <OrderTabList />
      {wizardStep === 0 && selectContractStep}
      {wizardStep === 1 && addOrderStep}
    </div>
  );
};

export default OrderPage;
