import { FunctionComponent, useState } from 'react';

import { Card } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../components/hooks/redux-hooks';
import OptionContractPicker from '../../components/panels/OptionContractPicker';
import { addSummaryOrder } from '../../store/order-summary/reducer';
import { getOrderSummaryBySymbolState } from '../../store/order-summary/selectors';
import { ContractDetails, OptionContract, OptionRight, OrderAction } from '../../types/entities';
import { createOptionPosition } from '../../utils/position-utils';

import OrderDetailsPanel from './OrderDetailsPanel';

interface Props {
  underlying: ContractDetails;
}

const OptionOrderPanel: FunctionComponent<Props> = ({ underlying }: Props) => {
  const symbol = underlying.contract.symbol;
  const orderSummaryState = useAppSelector((x) => x.orderSummaryState);
  const { orders } = getOrderSummaryBySymbolState(orderSummaryState, symbol);

  const [optionSelected, setOptionSelected] = useState<OptionContract | undefined>();
  const [orderAction, setOrderAction] = useState<OrderAction>('BUY');
  const [optionRight, setOptionRight] = useState<OptionRight>('CALL');

  const orderExists = optionSelected ? orders.find((x) => x.conId === optionSelected.conId) !== undefined : false;

  const dispatch = useAppDispatch();

  const handleAddOrderBtnClick = () => {
    if (optionSelected && !orderExists) {
      const order = createOptionPosition(
        optionSelected,
        optionRight,
        orderAction,
        'MKT',
        1,
        optionSelected.optionPrice,
        optionSelected.optionPrice
      );
      dispatch(addSummaryOrder({ symbol, order }));
    }
  };

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <OptionContractPicker
          underlying={underlying}
          selected={optionSelected}
          onOptionSelected={setOptionSelected}
          optionRight={optionRight}
          onOptionRightChanged={setOptionRight}
          orderAction={orderAction}
          onOrderActionChanged={setOrderAction}
        />
      </Card>
      {optionSelected && (
        <OrderDetailsPanel
          optionContract={optionSelected}
          underlying={underlying}
          orderAction={orderAction}
          contractAlias={`${underlying.contract.localSymbol} - ${optionSelected.localSymbol}`}
          limitPrice={optionSelected.strikePrice}
          onAddOrder={handleAddOrderBtnClick}
          addOrderDisabled={orderExists}
        />
      )}
    </>
  );
};

export default OptionOrderPanel;
