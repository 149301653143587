import { FunctionComponent } from 'react';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

interface Props {
  steps: number;
  activeStep: number;
  disableNext?: boolean;
  next?: () => void;
  previous?: () => void;
  my?: number | undefined;
  mx?: number | undefined;
}

const SimpleWizardNav: FunctionComponent<Props> = ({ steps, activeStep, previous, next, disableNext = false, my = 2, mx = 0 }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: activeStep > 0 ? 'space-between' : 'flex-end', my, mx }}>
      {activeStep > 0 && (
        <Button sx={{ pl: 0.75 }} variant="outlined" color="warning" onClick={previous} disabled={activeStep === 0}>
          <KeyboardArrowLeft />
          Back
        </Button>
      )}
      {activeStep < steps - 1 && (
        <Button
          sx={{ pr: 0.75 }}
          variant="outlined"
          color="primary"
          onClick={() => {
            if (next) next();
          }}
          disabled={disableNext}
        >
          Next
          <KeyboardArrowRight />
        </Button>
      )}
    </Box>
  );
};

export default SimpleWizardNav;
