import { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../components/hooks/redux-hooks';
import ContractDetailsPicker from '../../../components/panels/ContractDetailsPicker';
import GutterBox from '../../../components/ui/GutterBox';
import { setSelectedContractDetails } from '../../../store/user-settings/reducer';
import { ContractDetails } from '../../../types/entities';

import GotoOrderButton from './GotoOrderButton';
import WatchBoard from './Watchboard';

const WatchlistTab: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { selectedContractDetails } = useAppSelector((gs) => gs.userSettingsState);

  const handleSelectionChanged = (details: ContractDetails | undefined) => {
    dispatch(setSelectedContractDetails(details));
  };

  return (
    <div>
      <GutterBox sx={{ my: 3 }}>
        <Typography variant="h5">Watchlist</Typography>
      </GutterBox>
      <ContractDetailsPicker selected={selectedContractDetails} onSelectionChanged={handleSelectionChanged} />
      {selectedContractDetails && (
        <Box sx={{ mt: 2 }}>
          <WatchBoard details={selectedContractDetails} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <GotoOrderButton symbol={selectedContractDetails.contract.symbol}></GotoOrderButton>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default WatchlistTab;
