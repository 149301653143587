import { FunctionComponent, useEffect } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { GRAPH_PAGE_HOUR_CHART_ID } from '../../../../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../../../../components/hooks/redux-hooks';
import BarChartPanel from '../../../../../components/panels/BarChartPanel';
import { getContractsByUnderlyingSymbolState } from '../../../../../store/security-contract/selectors';
import { getSecurityContractsAsync } from '../../../../../store/security-contract/service';
import {
  setFavoritesPanelCollapsed,
  setSecurityPanelCollapsed,
  setSelectedContractDetails
} from '../../../../../store/user-settings/reducer';
import { Position } from '../../../../../types/entities';
import { BarSize } from '../../../../../types/enums';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
}

const GraphDialog: FunctionComponent<Props> = ({ isOpen, onClose, position }: Props) => {
  const isUnderlying = position.secType === 'FUT' || position.secType === 'STK';
  const entryPrice = isUnderlying ? position.entryPrice : position.underEntryPrice;
  const conId = isUnderlying ? position.conId : position.underConId;
  const symbol = isUnderlying ? position.symbol : position.underSymbol;
  const localSymbol = isUnderlying ? position.localSymbol : position.underSymbol;

  const { securities } = useAppSelector((gs) => gs.securityState);
  const security = securities.find((x) => x.symbol === position.symbol);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const secContractState = useAppSelector((gs) => gs.securityContractState);
  const { contracts, loaded } = getContractsByUnderlyingSymbolState(secContractState, position.symbol);
  const details = contracts.find((x) => x.contract.localSymbol === localSymbol);

  useEffect(() => {
    if (isOpen && security && !loaded) {
      dispatch(getSecurityContractsAsync(security));
    }
  }, [isOpen, security]);

  const handleGotoGraphPage = () => {
    dispatch(setSecurityPanelCollapsed(false));
    dispatch(setFavoritesPanelCollapsed(true));
    dispatch(setSelectedContractDetails(details));
    navigate(`/?tab=watchlist`);
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xl" onClose={onClose}>
      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ mt: 2 }}>
          <BarChartPanel
            barChartId={GRAPH_PAGE_HOUR_CHART_ID}
            conId={conId}
            exchange={position.exchange}
            title={`${symbol} - ${position.localSymbol}`}
            strikePrice={position.strikePrice}
            entryPrice={entryPrice}
            action={position.orderAction}
            initialBarSize={BarSize._1_HR}
            initialDuration={5}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGotoGraphPage} disabled={!details}>
          Goto <ArrowForwardIosIcon sx={{ ml: 1, fontSize: 16 }} />
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GraphDialog;
