import { FunctionComponent, useState } from 'react';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import EditPositionIconButton from '../../../../../components/ui/EditPositionIconButton';
import TimelineIconButton from '../../../../../components/ui/TimelineIconButton';
import VerticalSortIconButton from '../../../../../components/ui/VerticalSortIconButton';
import { Position } from '../../../../../types/entities';
import { getDaysAppart } from '../../../../../utils/datetime-utils';

import GraphDialog from './GraphDialog';

interface Props {
  position: Position;
}

const getDetails = (position: Position) => {
  const isOption = position.secType === 'FOP' || position.secType === 'OPT';
  if (!isOption) {
    return <Typography variant="caption">{position.localSymbol}</Typography>;
  }
  const expires = position.expirationDate;
  if (isOption) {
    const expiresDt = expires ? new Date(expires) : undefined;
    if (expiresDt) {
      const daysAppart = getDaysAppart(expiresDt, new Date());
      const daysAppartLabel = `${daysAppart}d`;
      const daysAppartColor = daysAppart < 10 ? '#ffe135' : 'inherit';
      return (
        <Typography variant="caption" color={daysAppartColor}>
          {daysAppartLabel}
        </Typography>
      );
    }
  }
};

const PositionHeader: FunctionComponent<Props> = ({ position }) => {
  const [showGraphDialog, setShowGraphDialog] = useState(false);
  const handleGraphBtnClick = () => setShowGraphDialog(true);
  const closeGraphDialog = () => setShowGraphDialog(false);

  const details = getDetails(position);

  return (
    <>
      <Box sx={{ mx: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption">{position.symbol}</Typography>
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          <Typography variant="caption">{position.secType}</Typography>
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          {details}
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          <Typography fontSize={12} fontFamily="monospace">
            {position.size > 0 ? '+' : ''}
            {position.size}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Tooltip title="Barchart" placement="top">
            <IconButton size="small" onClick={handleGraphBtnClick} sx={{ color: (theme) => theme.palette.grey[200] }}>
              <ShowChartIcon />
            </IconButton>
          </Tooltip>
          <TimelineIconButton position={position} size="small" />
          <Divider sx={{ mx: 0.5 }} orientation="vertical" flexItem />
          <VerticalSortIconButton position={position} direction="up" />
          <VerticalSortIconButton position={position} direction="down" />
          <EditPositionIconButton position={position} />
        </Box>
      </Box>
      {showGraphDialog && <GraphDialog isOpen={showGraphDialog} onClose={closeGraphDialog} position={position} />}
    </>
  );
};

export default PositionHeader;
