import { FunctionComponent } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Tooltip } from '@mui/material';

import { updatePositionAsync } from '../../store/position/service';
import { Position } from '../../types/entities';
import { useAppDispatch } from '../hooks/redux-hooks';

interface Props {
  position: Position;
  direction: 'up' | 'down';
}

const VerticalSortIconButton: FunctionComponent<Props> = ({ position, direction }: Props) => {
  const dispatch = useAppDispatch();
  const handleSortOrderBtnClick = (sortOrder: number) => {
    const pos: Position = { ...position, sortOrder: position.sortOrder + sortOrder };
    dispatch(updatePositionAsync(pos));
  };

  const title = direction === 'up' ? 'Move Up' : 'Move Down';
  const sortValue = direction === 'up' ? 1 : -1;
  const icon = direction === 'up' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;

  return (
    <Tooltip title={title} placement="top">
      <IconButton size="small" onClick={() => handleSortOrderBtnClick(sortValue)} sx={{ color: (theme) => theme.palette.grey[200] }}>
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default VerticalSortIconButton;
