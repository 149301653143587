import * as React from 'react';
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../components/hooks/redux-hooks';
import { logout } from '../../store/auth/service';

const Logout: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
    navigate('/login');
  }, []);

  return <div></div>;
};

export default Logout;
