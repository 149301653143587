import { useMemo } from 'react';

import { SecCatergory } from '../../types/enums';

import { useAppSelector } from './redux-hooks';

const useCategorySecurities = () => {
  const { securities } = useAppSelector((gs) => gs.securityState);
  const { selectedSecCategory } = useAppSelector((gs) => gs.userSettingsState);

  const filteredSecurities = useMemo(() => {
    switch (selectedSecCategory) {
      case SecCatergory.Commodity:
        return securities.filter((x) => x.category === 'Commodity');
      case SecCatergory.MicroCommodity:
        return securities.filter((x) => x.category === 'Micro');
      case SecCatergory.Stock:
        return securities.filter((x) => x.category === 'Stock');
      default:
        return [];
    }
  }, [selectedSecCategory]);

  return filteredSecurities;
};

export default useCategorySecurities;
