import { FunctionComponent } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { useAppDispatch } from '../../../../../components/hooks/redux-hooks';
import { updatePositionGroupAsync } from '../../../../../store/position-group/service';
import { PositionGroup } from '../../../../../types/entities';

import PositionGroupDetails from './PositionGroupDetails';
import PositionGroupSummary from './PositionGroupSummary';

interface Props {
  group: PositionGroup;
}

const PositionGroupBody: FunctionComponent<Props> = ({ group }: Props) => {
  const dispatch = useAppDispatch();

  const handleToggleCollapse = (_event: React.SyntheticEvent, expanded: boolean) => {
    dispatch(
      updatePositionGroupAsync({
        ...group,
        expanded
      })
    );
  };
  return (
    <Accordion disableGutters elevation={2} sx={{ backgroundColor: 'inherit' }} expanded={group.expanded} onChange={handleToggleCollapse}>
      <AccordionSummary sx={{ m: 0, p: 0, '& .MuiAccordionSummary-content': { m: 0 } }}>
        <PositionGroupSummary group={group} />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <PositionGroupDetails group={group} />
      </AccordionDetails>
    </Accordion>
  );
};

export default PositionGroupBody;
