import { Security } from '../../types/entities';

export const stocks: Security[] = [
  { name: 'Alphabet', symbol: 'GOOGL', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Amazon', symbol: 'AMZN', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Apple', symbol: 'AAPL', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Blink', symbol: 'BLNK', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'DaVita', symbol: 'DVA', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'DraftKings', symbol: 'DKNG', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Extra Space Storage', symbol: 'EXR', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'First Solar', symbol: 'FSL', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Intel Corp', symbol: 'INTC', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'IBM', symbol: 'IBM', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock', disabled: true },
  { name: 'Meta', symbol: 'META', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Microsoft', symbol: 'MSFT', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'NVIDIA', symbol: 'NVDA', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'PayCom', symbol: 'PAYC', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Palo Alto', symbol: 'PANW', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Salesforce', symbol: 'CRM', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'ServiceNow', symbol: 'NOW', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Shopify', symbol: 'SHOP', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Symatec', symbol: 'GEN', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' },
  { name: 'Tesla', symbol: 'TSLA', exchange: 'SMART', secType: 'STK', currency: 'USD', category: 'Stock' }
];

export const commodities: Security[] = [
  {
    name: 'SP 500 Mini',
    symbol: 'ES',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: 'Commodity',
    multiplier: '50',
    tickIncrement: 0.25
  },
  {
    name: 'Dow Jones Mini',
    symbol: 'YM',
    exchange: 'CBOT',
    secType: 'FUT',
    currency: 'USD',
    category: 'Commodity',
    multiplier: '5',
    tickIncrement: 1
  },
  {
    name: 'Corn',
    symbol: 'ZC',
    exchange: 'CBOT',
    secType: 'FUT',
    currency: 'USD',
    category: 'Commodity',
    multiplier: '5000',
    tickIncrement: 0.0025,
    quotePriceMultiplier: 100
  },
  {
    name: 'Russel 2000 Mini',
    symbol: 'RTY',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: 'Commodity',
    multiplier: '50',
    tickIncrement: 0.1
  },
  {
    name: 'Copper',
    symbol: 'HG',
    exchange: 'COMEX',
    secType: 'FUT',
    currency: 'USD',
    category: 'Commodity',
    multiplier: '25000',
    tickIncrement: 0.0005
  },
  { name: 'Nasdaq-100 Mini', symbol: 'NQ', exchange: 'CME', secType: 'FUT', currency: 'USD', category: 'Commodity' },
  { name: 'Crude Oil', symbol: 'CL', exchange: 'NYMEX', secType: 'FUT', currency: 'USD', category: 'Commodity' },
  { name: 'Gold', symbol: 'GC', exchange: 'COMEX', secType: 'FUT', currency: 'USD', category: 'Commodity' },
  { name: 'Bitcoin', symbol: 'BRR', exchange: 'CME', secType: 'FUT', currency: 'USD', category: 'Commodity' },
  { name: 'Ether', symbol: 'ETHUSDRR', exchange: 'CME', secType: 'FUT', currency: 'USD', category: 'Commodity' },
  { name: 'Silver', symbol: 'SI', exchange: 'COMEX', secType: 'FUT', currency: 'USD', category: 'Commodity' },
  { name: 'T-Bonds', symbol: 'ZB', exchange: 'CBOT', secType: 'FUT', currency: 'USD', category: 'Commodity' }
];

export const micros: Security[] = [
  {
    name: 'SP 500 Micro',
    parentSymbol: 'ES',
    symbol: 'MES',
    exchange: 'CME',
    secType: 'FUT',
    currency: 'USD',
    category: 'Micro',
    multiplier: '5',
    tickIncrement: 0.25
  },
  { name: 'Nasdaq-100 Micro', parentSymbol: 'NQ', symbol: 'MNQ', exchange: 'CME', secType: 'FUT', currency: 'USD', category: 'Micro' },
  { name: 'Crude Oil Micro', parentSymbol: 'CL', symbol: 'MCL', exchange: 'NYMEX', secType: 'FUT', currency: 'USD', category: 'Micro' },
  { name: 'Gold Micro', parentSymbol: 'GC', symbol: 'MGC', exchange: 'COMEX', secType: 'FUT', currency: 'USD', category: 'Micro' },
  { name: 'Bitcoin Micro', parentSymbol: 'BT', symbol: 'MBT', exchange: 'CME', secType: 'FUT', currency: 'USD', category: 'Micro' },
  { name: 'Ether Micro', parentSymbol: 'RT', symbol: 'MET', exchange: 'CME', secType: 'FUT', currency: 'USD', category: 'Micro' }
];

export const securities = stocks.concat(commodities).concat(micros);
