import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../components/hooks/redux-hooks';
import { useConfirm } from '../../components/hooks/useConfirm';
import EditPositionIconButton from '../../components/ui/EditPositionIconButton';
import TimelineIconButton from '../../components/ui/TimelineIconButton';
import VerticalSortIconButton from '../../components/ui/VerticalSortIconButton';
import { cancelOpenOrderAsync } from '../../store/open-order/service';
import { placeCloseOrderAsync as placeOptionCloseOrderAsync } from '../../store/order-option/service';
import { placeCloseOrderAsync as placeUnderlyingCloseOrderAsync } from '../../store/order-underlying/service';
import { OpenOrderReport, Position } from '../../types/entities';
import { formatNum, setThousenSeparator } from '../../utils/currency-utils';

interface Props {
  orders: Position[];
  symbol: string;
  onPositionSelected: (pos: Position | undefined) => void;
}

const ExistingPositionTable: FunctionComponent<Props> = ({ symbol, onPositionSelected }: Props) => {
  const [confirm, ConfirmDialog] = useConfirm();
  const dispatch = useAppDispatch();

  const { orders: openOrders } = useAppSelector((gs) => gs.openOrderState);
  const { positions } = useAppSelector((gs) => gs.positionState);
  const [securityPositions, setSecurityPositions] = useState(positions);

  const sortPositions = (a: Position, b: Position) => {
    if (!a || !b) {
      return 0;
    }
    return a.sortOrder < b.sortOrder ? 1 : -1;
  };

  useEffect(() => {
    const list = positions
      .slice()
      .filter((x) => x.symbol === symbol && !x.completed)
      .sort(sortPositions);
    setSecurityPositions(list);
  }, [positions]);

  const [positionSelected, setPositionSelected] = useState<Position | undefined>();

  const handleRowMouseClick = (pos: Position) => {
    const p = pos.conId !== positionSelected?.conId ? pos : undefined;
    setPositionSelected(p);
    onPositionSelected(p);
  };

  const stopPropagation = (event: React.MouseEvent<HTMLTableCellElement>) => {
    event.stopPropagation();
  };

  const handleCancelPlaceOrderBtnClick = async (event: React.MouseEvent<HTMLButtonElement>, openOrder: OpenOrderReport) => {
    event.stopPropagation();
    if (await confirm('Are you sure you want to cancel this order?')) {
      dispatch(cancelOpenOrderAsync(openOrder));
    }
  };

  const handleClosePositionBtnClick = async (event: React.MouseEvent<HTMLButtonElement>, pos: Position) => {
    event.stopPropagation();
    if (await confirm('Are you sure you want to close this position?')) {
      if (pos.secType === 'FOP' || pos.secType === 'OPT') {
        dispatch(placeOptionCloseOrderAsync(pos));
      } else {
        dispatch(placeUnderlyingCloseOrderAsync(pos));
      }
    }
  };

  if (securityPositions.length === 0) {
    return (
      <Box sx={{ m: 2 }}>
        <small>No Positions..</small>
      </Box>
    );
  }

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Sec Type</TableCell>
        <TableCell>Parent</TableCell>
        <TableCell>Local</TableCell>
        <TableCell align="center">Size</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Action</TableCell>
        <TableCell align="center">Right</TableCell>
        {/* <TableCell align="right">Entry Price</TableCell> */}
        <TableCell align="right">Price</TableCell>
        <TableCell align="right">Premium</TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );

  const orderRows = securityPositions.map((pos: Position, i) => {
    // const entryPrice = pos.entryPrice != 0 ? `$${formatNum(pos.entryPrice)}` : '-';
    const marketPrice = pos.marketPrice != 0 ? `$${formatNum(pos.marketPrice)}` : '-';
    const totalPremium = pos.premium * pos.size;
    const totalPremiumFormatted = totalPremium != 0 ? `$${setThousenSeparator(totalPremium.toFixed(0))}` : '-';
    const premiumColor = pos.premium == 0 ? 'Inherit' : pos.orderAction === 'BUY' ? '#e52b50' : '#00a86b';

    const openOrder = openOrders.find((x) => x.conId === pos.conId);
    const openOrderSize = openOrder?.size || 0;
    const openOrderSizeItem =
      openOrderSize !== 0 ? (
        <Tooltip title="Open Order Size">
          <Box sx={{ display: 'inline', ml: 1 }}>({openOrderSize})</Box>
        </Tooltip>
      ) : undefined;

    const showCanelOrderBtn = pos.size === 0 && openOrder;

    return (
      <TableRow
        key={`${pos.conId}-${i}`}
        selected={pos.conId === positionSelected?.conId}
        sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
        onClick={() => handleRowMouseClick(pos)}
      >
        <TableCell>{pos.secType}</TableCell>
        <TableCell>{pos.underSymbol}</TableCell>
        <TableCell>{pos.localSymbol}</TableCell>
        <TableCell align="center">
          {pos.size}
          {openOrderSizeItem}
        </TableCell>
        <TableCell>{pos.orderType}</TableCell>
        <TableCell>{pos.orderAction}</TableCell>
        <TableCell align="center">{pos.right || '-'}</TableCell>
        {/* <TableCell align="right">{entryPrice}</TableCell> */}
        <TableCell align="right">{marketPrice}</TableCell>
        <TableCell align="right" sx={{ color: premiumColor }}>
          {totalPremiumFormatted}
        </TableCell>
        <TableCell align="center" sx={{ px: 0.5 }} onClick={stopPropagation}>
          <TimelineIconButton position={pos} />
        </TableCell>
        <TableCell align="center" sx={{ px: 0.5, borderLeft: '1px solid rgb(81, 81, 81)' }} onClick={stopPropagation}>
          <VerticalSortIconButton position={pos} direction="up" />
        </TableCell>
        <TableCell align="center" sx={{ px: 0.5 }} onClick={stopPropagation}>
          <VerticalSortIconButton position={pos} direction="down" />
        </TableCell>
        <TableCell align="center" sx={{ px: 0.5 }} onClick={stopPropagation}>
          <EditPositionIconButton position={pos} />
        </TableCell>
        <TableCell align="right" sx={{ width: '100px', px: 1 }}>
          {showCanelOrderBtn ? (
            <Button size="small" color="warning" variant="contained" onClick={(e) => handleCancelPlaceOrderBtnClick(e, openOrder)}>
              Cancel
            </Button>
          ) : (
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={(e) => handleClosePositionBtnClick(e, pos)}
              disabled={pos.size === 0}
            >
              Close
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableContainer>
        <Table size="small">
          {tableHeader}
          <TableBody>{orderRows}</TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog />
    </>
  );
};

export default ExistingPositionTable;
