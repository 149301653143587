import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Skeleton, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { fetchScheduleLog } from '../../api';
import { updatePositionAsync } from '../../store/position/service';
import { Position, ScheduleLog } from '../../types/entities';
import { getDurationString, getFormattedExpireDateWithDuration } from '../../utils/datetime-utils';
import PositionScheduleForm from '../forms/PositionScheduleForm';
import { useAppDispatch } from '../hooks/redux-hooks';
import OptionVolatilityPanel from '../panels/OptionVolatilityPanel';
import PositionScheduleLog from '../panels/PositionScheduleLog';
import TopRightCloseButton from '../ui/TopRightCloseButton';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
}

const TimelineDialog: FunctionComponent<Props> = ({ isOpen, onClose, position }: Props) => {
  const [loading, setLoading] = useState(false);
  const [scheduleLog, setScheduleLog] = useState<ScheduleLog[]>([]);
  const [positionForm, setPositionForm] = useState(position);

  useEffect(() => {
    setPositionForm(position);
  }, [position]);

  const dispatch = useAppDispatch();

  const handleSaveBtnClick = () => {
    dispatch(updatePositionAsync(positionForm));
  };

  const fetchScheduleLogAsync = async () => {
    setLoading(true);
    try {
      const data = (await fetchScheduleLog(position.id)).data;
      setScheduleLog(data);
    } catch (e) {
      const error = e as AxiosError<{ message: string }>;
      const msg = error.response?.data.message || error.message;
      toast.error(msg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchScheduleLogAsync();
    }
  }, [isOpen, position.id]);

  let duration = '';
  if (!!position.entryDate) {
    const entryDt = new Date(position.entryDate);
    duration = getDurationString(entryDt, new Date());
  }

  const subTitle = getFormattedExpireDateWithDuration(position.expirationDate);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="md" onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5">Position Timeline</Typography>
        {subTitle && (
          <Typography component="div" color="wheat" sx={{ mt: 1, typography: { sm: 'body1', xs: 'body2' } }}>
            {subTitle}
          </Typography>
        )}
        {position.right && (
          <Box sx={{ mt: 1 }}>
            <OptionVolatilityPanel
              orderAction={position.orderAction}
              entryToExpireHistVlt={position.entryToExpireHistVlt}
              entryToExpireImpliedVlt={position.entryToExpireImpliedVlt}
              entryToStrikeVlt={position.entryToStrikeVlt}
              entryToDateVlt={position.entryToDateVlt}
            />
          </Box>
        )}
        <TopRightCloseButton onClose={onClose} />
      </Box>

      <DialogContent sx={{ p: 0, maxHeight: '50vh' }}>
        <Box sx={{ my: 2 }}>
          {loading && <Skeleton sx={{ mx: 2 }} variant="rounded" animation="wave" height={80} />}
          {!loading && <PositionScheduleLog events={scheduleLog} />}
        </Box>

        {duration && (
          <Typography variant="caption" component="div" sx={{ mt: 1, mx: 2 }}>
            <i>Time Elapsed Since Entry: {duration}</i>
          </Typography>
        )}
        <PositionScheduleForm p={2} position={positionForm} onChange={setPositionForm} />

        <Divider />
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="contained" color="primary" onClick={handleSaveBtnClick}>
          Save
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimelineDialog;
