import { FunctionComponent } from 'react';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';

import useToggleFavorite from '../../../../components/hooks/useToggleFavorite';
import { ContractDetails, Security } from '../../../../types/entities';

interface Props {
  security: Security;
  details: ContractDetails;
}

const WatchBoardHeader: FunctionComponent<Props> = ({ security, details }) => {
  const [isFavorite, toggleFavorite] = useToggleFavorite();
  const { contract } = details;
  const title = `${security.name} - ${contract.localSymbol}`;

  return (
    <Box>
      <Paper sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6">Watch Board</Typography>
          <Typography variant="caption">{title}</Typography>
        </Box>
        <Tooltip title="Add to favorites" placement="left-start">
          <IconButton size="small" color="primary" onClick={() => toggleFavorite(details)}>
            {isFavorite(details) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
        </Tooltip>
      </Paper>
    </Box>
  );
};

export default WatchBoardHeader;
