import { FunctionComponent } from 'react';

import { Grid, FormControlLabel, Switch, TextField } from '@mui/material';

import { TradePreset } from '../../types/entities';

interface Props {
  tradePreset: TradePreset;
  onChange: (tp: TradePreset) => void;
}

/**
 * @deprecated
 * Use PositionScheduleForm instead
 */
const TradePresetForm: FunctionComponent<Props> = ({ tradePreset, onChange: updateTradePreset }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateForm = (field: keyof TradePreset, value: any) => {
    const copy = {
      ...tradePreset,
      [field]: value
    } as TradePreset;
    updateTradePreset(copy);
  };

  return (
    <Grid container width="100%" spacing={2} sx={{ boxSizing: 'border-box' }}>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={
            <Switch onChange={() => handleUpdateForm('useSmallProfit', !tradePreset.useSmallProfit)} checked={tradePreset.useSmallProfit} />
          }
          label="Use Small Profit"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          label="Hours Delayed"
          variant="outlined"
          type="number"
          value={tradePreset.smallProfitDelayHours}
          onChange={(e) => handleUpdateForm('smallProfitDelayHours', Number(e.target.value))}
          disabled={!tradePreset.useSmallProfit}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          label="Small Profit"
          variant="outlined"
          type="number"
          value={tradePreset.smallProfit}
          onChange={(e) => handleUpdateForm('smallProfit', Number(e.target.value))}
          disabled={!tradePreset.useSmallProfit}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={<Switch onChange={() => handleUpdateForm('useTimeOut', !tradePreset.useTimeOut)} checked={tradePreset.useTimeOut} />}
          label="Use Time Out"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          label="Hours Delayed"
          variant="outlined"
          type="number"
          value={tradePreset.timeOutDelayHours}
          onChange={(e) => handleUpdateForm('timeOutDelayHours', Number(e.target.value))}
          disabled={!tradePreset.useTimeOut}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default TradePresetForm;
