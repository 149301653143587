import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { RESEARCH_PAGE_DAILY_CHART_ID, RESEARCH_PAGE_HOUR_CHART_ID } from '../../../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../../../components/hooks/redux-hooks';
import BarChartPanel from '../../../../components/panels/BarChartPanel';
import MarketDataPanel from '../../../../components/panels/MarketDataPanel';
import NewsTable from '../../../../components/panels/NewsPanel/NewsTable';
import TrendLinePanel from '../../../../components/panels/TrendLinePanel';
import AccordionWrapper from '../../../../components/ui/AccordionWrapper';
import {
  setDailyGraphCollapsed,
  setHourGraphCollapsed,
  setIndicatorPanelCollapsed,
  setNewsPanelCollapsed,
  setTrendLinesCollapsed
} from '../../../../store/user-settings/reducer';
import { ContractDetails } from '../../../../types/entities';
import { BarSize } from '../../../../types/enums';

import WatchBoardHeader from './WatchBoardHeader';

interface Props {
  details: ContractDetails;
  entryPrice?: number | undefined;
  strikePrice?: number | undefined;
  action?: string | undefined;
}
const WatchBoard: FunctionComponent<Props> = ({ details, entryPrice, strikePrice, action }: Props) => {
  const dispatch = useAppDispatch();
  const userSettingsState = useAppSelector((gs) => gs.userSettingsState);
  const { newsPanelCollapsed, indicatorPanelCollapsed, hourGraphCollapsed, dailyGraphCollapsed } = userSettingsState;
  const toggleNewsPanelCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setNewsPanelCollapsed(!expanded));
  const toggleIndicatorPanelCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setIndicatorPanelCollapsed(!expanded));
  const toggleDailyGraphCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setDailyGraphCollapsed(!expanded));
  const toggleHourGraphCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setHourGraphCollapsed(!expanded));
  const { trendLinesCollapsed } = useAppSelector((gs) => gs.userSettingsState);
  const toggleTrendLinesCollapsed = () => dispatch(setTrendLinesCollapsed(!trendLinesCollapsed));

  const contract = details?.contract;
  const securityState = useAppSelector((gs) => gs.securityState);
  const securities = securityState.securities;
  const security = securities.find((x) => x.symbol === contract.symbol);

  return (
    <Box>
      {security && <WatchBoardHeader security={security} details={details} />}
      <AccordionWrapper title="News" collapsed={newsPanelCollapsed} onChange={toggleNewsPanelCollapsed}>
        <NewsTable security={security} />
      </AccordionWrapper>
      <AccordionWrapper title="Indicators" collapsed={indicatorPanelCollapsed} onChange={toggleIndicatorPanelCollapsed}>
        <Box sx={{ mx: 2 }}>
          <MarketDataPanel contract={contract} />
        </Box>
      </AccordionWrapper>
      <AccordionWrapper title="Daily Barchart" collapsed={dailyGraphCollapsed} onChange={toggleDailyGraphCollapsed}>
        <BarChartPanel
          barChartId={RESEARCH_PAGE_DAILY_CHART_ID}
          conId={contract.conId}
          title={contract.localSymbol}
          exchange={contract.exchange}
          initialBarSize={BarSize._1_DAY}
          initialDuration={50}
          entryPrice={entryPrice}
          strikePrice={strikePrice}
          action={action}
        />
      </AccordionWrapper>
      <AccordionWrapper title="Hour Barchart" collapsed={hourGraphCollapsed} onChange={toggleHourGraphCollapsed}>
        <BarChartPanel
          barChartId={RESEARCH_PAGE_HOUR_CHART_ID}
          conId={contract.conId}
          title={contract.localSymbol}
          exchange={contract.exchange}
          initialBarSize={BarSize._1_HR}
          initialDuration={5}
          entryPrice={entryPrice}
          strikePrice={strikePrice}
          action={action}
        />
      </AccordionWrapper>
      <AccordionWrapper title="Trend Lines" collapsed={trendLinesCollapsed} onChange={toggleTrendLinesCollapsed}>
        <TrendLinePanel conId={contract.conId} />
      </AccordionWrapper>
    </Box>
  );
};

export default WatchBoard;
