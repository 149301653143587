import { FunctionComponent, useEffect, useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import { FormControlLabel, Switch, TextField } from '@mui/material';

import TopRightCloseButton from '../../../components/ui/TopRightCloseButton';
import { Position } from '../../../types/entities';
import { getFormattedExpireDateWithDuration } from '../../../utils/datetime-utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
  onDelete: (pos: Position) => void;
  onChange: (pos: Position) => void;
}

const EditOrderDialog: FunctionComponent<Props> = ({ isOpen, onClose, position, onDelete, onChange }: Props) => {
  const [positionForm, setPositionForm] = useState(position);
  const subTitle = getFormattedExpireDateWithDuration(position.expirationDate);

  useEffect(() => {
    setPositionForm(positionForm);
  }, [position]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateForm = (field: keyof Position, value: any) => {
    const copy = {
      ...positionForm,
      [field]: value
    } as Position;
    setPositionForm(copy);
  };

  const handleSaveBtnClick = () => {
    onChange(positionForm);
    onClose();
  };

  const handleRemoveBtnClick = () => {
    onDelete(positionForm);
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="xs" onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5">Edit Order</Typography>
        {subTitle && (
          <Typography variant="caption" component="div" sx={{ mt: 1 }}>
            <i>{subTitle}</i>
          </Typography>
        )}
        <TopRightCloseButton onClose={onClose} />
      </Box>

      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            sx={{ mt: 1 }}
            size="small"
            label="Order Size"
            variant="outlined"
            type="number"
            value={positionForm.orderSize}
            onChange={(e) => handleUpdateForm('orderSize', Number(e.target.value))}
            fullWidth
          />
          <Divider>
            <Typography variant="caption">SCHEDULE</Typography>
          </Divider>

          <FormControlLabel
            control={
              <Switch
                onChange={() => handleUpdateForm('useSmallProfit', !positionForm.useSmallProfit)}
                checked={positionForm.useSmallProfit}
              />
            }
            label="Use Small Profit"
          />
          <TextField
            size="small"
            label="Hours Delayed"
            variant="outlined"
            type="number"
            value={positionForm.smallProfitDelayHours}
            onChange={(e) => handleUpdateForm('smallProfitDelayHours', Number(e.target.value))}
            disabled={!positionForm.useSmallProfit}
            fullWidth
          />
          <TextField
            size="small"
            label="Small Profit"
            variant="outlined"
            type="number"
            value={positionForm.smallProfit}
            onChange={(e) => handleUpdateForm('smallProfit', Number(e.target.value))}
            disabled={!positionForm.useSmallProfit}
            fullWidth
          />
          <FormControlLabel
            control={<Switch onChange={() => handleUpdateForm('useTimeOut', !positionForm.useTimeOut)} checked={positionForm.useTimeOut} />}
            label="Use Time Out"
          />
          <TextField
            size="small"
            label="Hours Delayed"
            variant="outlined"
            type="number"
            value={positionForm.timeOutDelayHours}
            onChange={(e) => handleUpdateForm('timeOutDelayHours', Number(e.target.value))}
            disabled={!positionForm.useTimeOut}
            fullWidth
          />
        </Box>
      </DialogContent>

      <Divider />

      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button size="small" variant="contained" color="error" onClick={handleRemoveBtnClick}>
          Remove
        </Button>
        <Box>
          <Button sx={{ mr: 1 }} size="small" variant="contained" color="primary" onClick={handleSaveBtnClick}>
            Save
          </Button>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderDialog;
