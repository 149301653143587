import { FunctionComponent } from 'react';

import { Box, Card, Divider } from '@mui/material';

import { ORDER_SUMMARY_PAGE_DAILY_CHART_ID } from '../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../components/hooks/redux-hooks';
import BarChartPanel from '../../components/panels/BarChartPanel';
import OptionVolatilityPanel from '../../components/panels/OptionVolatilityPanel';
import TrendLinePanel from '../../components/panels/TrendLinePanel';
import AccordionWrapper from '../../components/ui/AccordionWrapper';
import { setTrendLinesCollapsed } from '../../store/user-settings/reducer';
import { Position } from '../../types/entities';

interface Props {
  order: Position | undefined;
}

const OrderSelectedPanel: FunctionComponent<Props> = ({ order }: Props) => {
  const dispatch = useAppDispatch();

  const { trendLinesCollapsed } = useAppSelector((gs) => gs.userSettingsState);
  const toggleTrendLinesCollapsed = () => dispatch(setTrendLinesCollapsed(!trendLinesCollapsed));

  if (!order) {
    return null;
  }
  const isOption = order.secType === 'FOP' || order.secType === 'OPT';
  const conId = isOption ? order.underConId : order.conId;
  const entryPrice = !isOption ? order.entryPrice : undefined;
  return (
    <Card sx={{ mt: 2 }}>
      <Box sx={{ mt: 1, mx: 2 }}>
        <OptionVolatilityPanel
          orderAction={order.orderAction}
          entryToExpireHistVlt={order.entryToExpireHistVlt}
          entryToExpireImpliedVlt={order.entryToExpireImpliedVlt}
          entryToStrikeVlt={order.entryToStrikeVlt}
        />
      </Box>
      <Divider sx={{ mt: 1 }} />
      <Box sx={{ p: 1 }}>
        <BarChartPanel
          barChartId={ORDER_SUMMARY_PAGE_DAILY_CHART_ID}
          conId={conId}
          title={`${order.symbol} - ${order.localSymbol}`}
          exchange={order.exchange}
          entryPrice={entryPrice}
          strikePrice={order.strikePrice}
          action={order.orderAction}
        />
      </Box>
      <AccordionWrapper title="Trend Lines" collapsed={trendLinesCollapsed} onChange={toggleTrendLinesCollapsed}>
        <TrendLinePanel conId={conId} />
      </AccordionWrapper>
    </Card>
  );
};

export default OrderSelectedPanel;
