import { FunctionComponent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';

import { getFundamentalLinkBySymbol } from '../../../store/fundamental-link/selectors';
import { getFundamentalLinksAsync } from '../../../store/fundamental-link/service';
import { FundamentalLink, Security } from '../../../types/entities';
import { FundamentalLinkType } from '../../../types/enums';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';

import FundamentalLinkDialog from './EditNewsLinkDialog';

interface Props {
  security?: Security | undefined;
  hideAddLinkBtn?: boolean;
}

const NewsTable: FunctionComponent<Props> = ({ security, hideAddLinkBtn = false }: Props) => {
  const symbol = security?.parentSymbol || security?.symbol;

  const fundamentalLinkState = useAppSelector((gs) => gs.fundamentalLinkState);
  const { loading, loaded, links } = getFundamentalLinkBySymbol(fundamentalLinkState, symbol || '*');

  const [selectedLink, setSelectedLink] = useState<FundamentalLink | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const dispatch = useAppDispatch();

  const [initialLoaded, setInitialLoaded] = useState(false);
  useEffect(() => {
    setInitialLoaded(true);
  }, []);

  useEffect(() => {
    if (!loading && !loaded) {
      dispatch(getFundamentalLinksAsync(symbol));
    }
  }, [loading, loaded, symbol]);

  const handleAddLinkBtnClick = () => {
    setSelectedLink(undefined);
    setShowEditDialog(true);
  };

  const handleLinkBtnClick = (link: FundamentalLink) => {
    window.open(link.url);
  };

  const handleEditBtnClick = (event: React.MouseEvent<HTMLElement>, link: FundamentalLink) => {
    event.stopPropagation();
    setSelectedLink(link);
    setShowEditDialog(!!link);
  };

  const addLinkButton = !hideAddLinkBtn ? (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
      <Tooltip title="Add News Link" placement="left-start">
        <Button size="small" color="primary" variant="outlined" onClick={handleAddLinkBtnClick}>
          <AddIcon sx={{ fontSize: '1.25rem' }} />
        </Button>
      </Tooltip>
    </Box>
  ) : undefined;

  if (!initialLoaded && loading) {
    return <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={50} />;
  }

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Title</TableCell>
        <TableCell>Keywords</TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );

  const sortByOrderAndTitle = (a: FundamentalLink, b: FundamentalLink) => {
    if (a.sortOrder > b.sortOrder) return 1;
    if (a.sortOrder < b.sortOrder) return -1;
    return a.title > b.title ? 1 : -1;
  };

  const tableBody = (
    <TableBody>
      {links
        .slice()
        .sort(sortByOrderAndTitle)
        .map((x) => (
          <TableRow key={x.id} sx={{ cursor: 'pointer' }} onClick={() => handleLinkBtnClick(x)}>
            <TableCell
              sx={{
                whiteSpace: { xs: 'wrap', sm: 'nowrap' },
                color: (theme) => (x.type === FundamentalLinkType.General ? theme.palette.success.light : theme.palette.info.light)
              }}
            >
              {x.title}
            </TableCell>
            <TableCell sx={{ width: '100%' }}>{x.tooltip}</TableCell>
            <TableCell align="right" sx={{ width: '50px', px: 1 }}>
              <Button size="small" onClick={(e) => handleEditBtnClick(e, x)}>
                Edit
              </Button>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );

  const table = (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        {tableHeader}
        {tableBody}
      </Table>
    </TableContainer>
  );

  return (
    <>
      <Box sx={{ flexGrow: 1, mb: 1.5 }}>
        {links.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ px: 2 }} fontSize="smaller" component="div">
              No news-links exists..
            </Typography>
            {addLinkButton}
          </Box>
        ) : (
          <>
            {table}
            {addLinkButton}
          </>
        )}
      </Box>

      <FundamentalLinkDialog symbol={symbol} link={selectedLink} isOpen={showEditDialog} onClose={closeDialog} />
    </>
  );
};

export default NewsTable;
