import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { checkOrderMargin, placeEntryOrder } from '../../api';
import { placeCloseOrder } from '../../api';
import { CheckMarginParams, Position, PlaceOrderReport } from '../../types/entities';
import { OrderStatusType } from '../../types/enums';
import { Logger } from '../../utils/Logger';
//import { loaded as dashboardLoaded } from '../dashboard/reducer';

import { loading, fail, setMargin, setEntryOrderReport, setCloseOrderReport } from './reducer';

export const checkUnderlyingMarginAsync = (params: CheckMarginParams) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    checkOrderMargin(params)
      .then((response) => {
        const orderState = response.data;
        Logger.log(orderState);
        dispatch(setMargin(orderState));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const placeEntryOrderAsync = (params: Position) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    placeEntryOrder(params)
      .then((response) => {
        const placeOrderReport = response.data;
        Logger.log(placeOrderReport);
        dispatch(setEntryOrderReport(placeOrderReport));
        // dispatch(dashboardLoaded(false));
        if (placeOrderReport.status === OrderStatusType.Filled) {
          toast.success('Entry Order submitted successfully!');
        } else if (!!response.status) {
          const responseText = `Entry Order submitted with status: ${OrderStatusType[response.status]}`;
          toast.warning(responseText);
        }
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const placeCloseOrderAsync = (pos: Position) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    placeCloseOrder(pos)
      .then((response) => {
        Logger.log(response);
        const report: PlaceOrderReport = response.data;
        dispatch(setCloseOrderReport(report));
        //dispatch(dashboardLoaded(false));
        if (report.status === OrderStatusType.Filled) {
          toast.success('Close Order submitted successfully!');
        } else if (!!response.status) {
          const responseText = `Close Order submitted with status: ${OrderStatusType[response.status]}`;
          toast.warning(responseText);
        }
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        toast.error(msg);
      });
  };
};
