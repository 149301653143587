import { FunctionComponent, useEffect, useState } from 'react';

import { Card, Skeleton } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../components/hooks/redux-hooks';
import OrderTypePicker from '../../components/panels/OrderTypePicker';
import { getMarketDataStateByConId } from '../../store/market-data/selectors';
import { getMarketDataAsync } from '../../store/market-data/service';
import { addSummaryOrder } from '../../store/order-summary/reducer';
import { getOrderSummaryBySymbolState } from '../../store/order-summary/selectors';
import { ContractDetails, MarketDataParams, OrderAction, OrderType } from '../../types/entities';
import { createUnderlyingPosition } from '../../utils/position-utils';

import OrderDetailsPanel from './OrderDetailsPanel';

interface Props {
  underlying: ContractDetails;
}

const OrderUnderlyingPanel: FunctionComponent<Props> = ({ underlying }: Props) => {
  const contract = underlying.contract;
  const symbol = contract.symbol;
  const orderSummaryState = useAppSelector((x) => x.orderSummaryState);
  const { orders } = getOrderSummaryBySymbolState(orderSummaryState, symbol);
  const orderExists = orders.find((x) => x.conId === contract.conId) !== undefined;

  const { useFrozen } = useAppSelector((gs) => gs.userSettingsState);

  const marketDataState = useAppSelector((gs) => gs.marketDataState);
  const marketDataByConId = getMarketDataStateByConId(marketDataState, contract.conId);
  const { marketData, loaded, loading, error } = marketDataByConId;
  let marketPrice = 0;
  if (!!marketData) {
    const { lastPrice, askPrice, bidPrice } = marketData;
    marketPrice = lastPrice > 0 ? lastPrice : (askPrice + bidPrice) / 2;
  }

  const [orderAction, setOrderAction] = useState<OrderAction>('BUY');
  const [orderType, setOrderType] = useState<OrderType>('MKT');
  const [entryPrice, setEntryPrice] = useState<number>(marketPrice || 0);

  useEffect(() => {
    if (marketPrice) {
      setEntryPrice(marketPrice);
    }
  }, [orderAction, orderType, marketPrice]);

  const dispatch = useAppDispatch();

  const fetchMarketData = () => {
    const params: MarketDataParams = {
      conId: contract.conId,
      symbol: contract.symbol,
      localSymbol: contract.localSymbol,
      exchange: contract.exchange,
      currency: contract.currency,
      secType: contract.secType,
      right: contract.right,
      strike: contract.strike,
      lastTradeDateOrContractMonth: contract.lastTradeDateOrContractMonth,
      multiplier: contract.multiplier,
      pricesOnly: false,
      useFrozen
    };
    dispatch(getMarketDataAsync(params));
  };

  useEffect(() => {
    if (!loaded) {
      fetchMarketData();
    }
  }, [contract]);

  const handleAddOrderBtnClick = () => {
    if (!orderExists) {
      const order = createUnderlyingPosition(underlying, orderAction, orderType, 1, entryPrice, marketPrice);
      dispatch(addSummaryOrder({ symbol, order }));
    }
  };

  return (
    <>
      {loading && <Skeleton variant="rounded" animation="wave" height="100px" />}

      <Card sx={{ mb: 2 }}>
        {!loading && !error && (
          <OrderTypePicker
            orderAction={orderAction}
            onOrderActionChanged={setOrderAction}
            orderType={orderType}
            onOrderTypeChanged={setOrderType}
            marketPrice={marketPrice}
            entryPrice={entryPrice}
            onEntryPriceChanged={setEntryPrice}
          />
        )}
      </Card>

      <OrderDetailsPanel
        underlying={underlying}
        orderAction={orderAction}
        entryPrice={entryPrice}
        onAddOrder={handleAddOrderBtnClick}
        addOrderDisabled={orderExists}
      />
    </>
  );
};

export default OrderUnderlyingPanel;
