import { FunctionComponent } from 'react';

import { Box, Card, Typography } from '@mui/material';

import { Security } from '../../../types/entities';

import AddNewsLinkButton from './AddNewsLinkButton';
import NewsTable from './NewsTable';

interface Props {
  security?: Security | undefined;
}

const NewsPanel: FunctionComponent<Props> = ({ security }: Props) => {
  const title = security ? `${security.name} - News Links` : 'News Links';
  return (
    <Card>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
        <Typography variant="h6">{title}</Typography>
        <AddNewsLinkButton security={security} />
      </Box>
      <NewsTable security={security} hideAddLinkBtn />
    </Card>
  );
};

export default NewsPanel;
