import { FunctionComponent, useEffect } from 'react';

import { Box, Button, CircularProgress, Skeleton, Typography } from '@mui/material';

import { getContractsByUnderlyingSymbolState } from '../../../store/security-contract/selectors';
import { getSecurityContractsAsync } from '../../../store/security-contract/service';
import { setSelectedSecurity } from '../../../store/user-settings/reducer';
import { ContractDetails } from '../../../types/entities';
import { Security } from '../../../types/entities';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import useCategorySecurities from '../../hooks/useCategorySecurities';
import SecurityCategoryTabs from '../../ui/SecurityCategoryTabs';

import ContractDetailsTable from './ContractDetailsTable';

interface Props {
  selected: ContractDetails | undefined;
  onSelectionChanged: (contract: ContractDetails | undefined) => void;
}

const ContractDetailsPanel: FunctionComponent<Props> = ({ selected, onSelectionChanged }: Props) => {
  const selectedConId = selected?.contract?.conId || 0;
  const { securities } = useAppSelector((gs) => gs.securityState);
  const filteredSecurities = useCategorySecurities();
  const { selectedSecurity } = useAppSelector((gs) => gs.userSettingsState);

  const dispatch = useAppDispatch();
  const handleSetSelectedSecurity = (sec: Security | undefined) => dispatch(setSelectedSecurity(sec));

  const conFutState = useAppSelector((gs) => gs.securityContractState);
  const { contracts, initialLoaded, loaded, loading } = getContractsByUnderlyingSymbolState(conFutState, selectedSecurity?.symbol || '');

  const onCategoryChanged = () => {
    onSelectionChanged(undefined);
  };

  useEffect(() => {
    if (!loaded) {
      handleReloadContracts();
    }
  }, [selectedSecurity]);

  useEffect(() => {
    if (selected) {
      const security = securities.find((x) => x.symbol === selected.contract.symbol);
      const needsUpdate = !!security && (!selectedSecurity || security.symbol !== selectedSecurity.symbol);
      if (needsUpdate) {
        handleSetSelectedSecurity(security);
      }
    }
  }, [selected]);

  const handleReloadContracts = () => {
    if (selectedSecurity) {
      dispatch(getSecurityContractsAsync(selectedSecurity));
    }
  };

  const handleSelectContractDetails = (details: ContractDetails) => {
    onSelectionChanged(details);
  };

  if (!selectedSecurity) {
    return (
      <Box sx={{ mx: 2 }}>
        <Typography sx={{ pb: 0.5 }} variant="caption" component="div">
          SELECT SECURITY
        </Typography>
        <SecurityCategoryTabs onCategoryChanged={onCategoryChanged} />
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 3 }}>
          {filteredSecurities.map((x) => (
            <Button key={x.symbol} onClick={() => handleSetSelectedSecurity(x)} variant="outlined">
              {x.name}
            </Button>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography sx={{ mx: 2, pb: 1 }} variant="caption" component="div">
        SELECT CONTRACT
      </Typography>

      {!initialLoaded ? (
        <Skeleton sx={{ mb: 2, mx: 2 }} variant="rounded" animation="wave" height={50} />
      ) : (
        <ContractDetailsTable list={contracts} maxRows={6} selectedConId={selectedConId} onSelect={handleSelectContractDetails} />
      )}

      <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button size="small" color="warning" variant="outlined" onClick={() => handleSetSelectedSecurity(undefined)}>
          Go Back
        </Button>
        <Button size="small" variant="outlined" onClick={handleReloadContracts}>
          Reload Contracts {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
        </Button>
      </Box>
    </Box>
  );
};

export default ContractDetailsPanel;
