import { FunctionComponent } from 'react';

import { Container, Typography } from '@mui/material';

import ResponsiveContainer from '../../components/ui/ResponsiveContainer';

import UserSettingsPanel from './UserSettingsPanel';

const Settings: FunctionComponent = () => {
  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Settings
        </Typography>
      </Container>
      <ResponsiveContainer>
        <UserSettingsPanel />
      </ResponsiveContainer>
    </>
  );
};

export default Settings;
