import { FunctionComponent, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import { completePositionAsync, updatePositionAsync } from '../../store/position/service';
import { Position } from '../../types/entities';
import SelectDateTimeControl from '../form-controls/SelectDateTimeControl';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { useConfirm } from '../hooks/useConfirm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
}

const EditPositionDialog: FunctionComponent<Props> = ({ isOpen, onClose, position }: Props) => {
  const [confirm, ConfirmDialog] = useConfirm();
  const { loaded, error } = useAppSelector((gs) => gs.positionState);
  const { groups } = useAppSelector((gs) => gs.positionGroupState);
  const [actionPressed, setActionPressed] = useState(false);
  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const openOrder = openOrderState.orders.find((x) => x.conId === position.conId);
  const canCompletePosition = !openOrder && position.size === 0 && !!position.exitDate;

  const [groupId, setGroupId] = useState<string>(position.groupId || '');
  const [portfolioRlzPnl, setPortfolioRlzPnl] = useState<number>(position.portfolioRlzPnl);
  const [realizedPnl, setRealizedPnl] = useState<number>(position.realizedPnl);
  const [exitDate, setExitDate] = useState<Date | undefined>(position.exitDate ? new Date(position.exitDate) : undefined);

  useEffect(() => {
    setGroupId(position.groupId || '');
    setPortfolioRlzPnl(position.portfolioRlzPnl);
    setRealizedPnl(position.realizedPnl);
    setExitDate(position.exitDate ? new Date(position.exitDate) : undefined);
  }, [isOpen]);

  const handleCloseDialog = () => {
    setActionPressed(false);
    onClose();
  };

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && loaded && !error) {
      handleCloseDialog();
    }
  });

  const dispatch = useAppDispatch();

  const handleSave = () => {
    setActionPressed(false);
    const pos = {
      ...position,
      groupId,
      realizedPnl,
      exitDate
    };
    dispatch(updatePositionAsync(pos));
  };

  const handleRemoveBtnClick = async () => {
    if (await confirm('Are you sure you want to remove the position?')) {
      removePosition();
    }
  };

  /**
   * removePosition sets the completed flag to true and exitDate to undefined,
   * which will remove the position, and not show the position in the Trade History.
   */
  const removePosition = async () => {
    setActionPressed(true);
    const pos = {
      ...position,
      completed: true,
      exitDate: undefined
    };
    dispatch(updatePositionAsync(pos));
  };

  const handleCompleteBtnClick = async () => {
    setActionPressed(true);
    dispatch(completePositionAsync(position));
  };

  // const realizedPnlUnlockedLabel = 'Enable Realized PnL (not $0) to be overwritten';
  // const lockPnlInfo = realizedPnlUnlocked ? (
  //   <Tooltip title="Realized PnL is unlocked for new updates">
  //     <LockOpenIcon sx={{ fontSize: 16 }} />
  //   </Tooltip>
  // ) : (
  //   <Tooltip title="Realized PnL is locked and will only be updated if it's value is 0">
  //     <LockIcon sx={{ fontSize: 16 }} />
  //   </Tooltip>
  // );

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Box>
          <Typography variant="h6">Edit Position</Typography>
          <Typography>
            {position.underSymbol} - {position.localSymbol}
          </Typography>
        </Box>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCloseDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <FormControl fullWidth>
          <InputLabel id="month-label" size="small">
            Position Group
          </InputLabel>
          <Select
            name="group-label"
            labelId="group-label"
            value={groupId ?? ''}
            label="Position Group"
            onChange={(e) => setGroupId(e.target.value)}
            size="small"
          >
            <MenuItem key="" value="">
              Velg..
            </MenuItem>
            {}
            {groups.map((x) => (
              <MenuItem key={x.id} value={x.id}>
                {x.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <SelectDateTimeControl label="Exit Timestamp" selectedValue={exitDate} onDateSelected={setExitDate} />
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
          <FormControl>
            <TextField
              name="name"
              size="small"
              label="Portfolio Rlz. PnL"
              variant="outlined"
              type="number"
              value={portfolioRlzPnl}
              onChange={(e) => setPortfolioRlzPnl(Number(e.target.value))}
            />
          </FormControl>
          <IconButton aria-label="Copy value" onClick={() => setRealizedPnl(portfolioRlzPnl)}>
            <Tooltip title="Copy Realized PnL Portfolio value to Position value">
              <DoubleArrowIcon />
            </Tooltip>
          </IconButton>
          <FormControl>
            <TextField
              name="name"
              size="small"
              label="Position Rlz. PnL"
              variant="outlined"
              type="number"
              value={realizedPnl}
              onChange={(e) => setRealizedPnl(Number(e.target.value))}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
            <Button onClick={handleCloseDialog} variant="outlined">
              Cancel
            </Button>
          </Box>
        </Box>
        <Divider sx={{ width: '100%', my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: 1 }}>
          <Button variant="outlined" color="success" onClick={handleCompleteBtnClick} disabled={!canCompletePosition} fullWidth>
            Complete
          </Button>
          <Button variant="outlined" color="error" onClick={handleRemoveBtnClick} fullWidth>
            Remove
          </Button>
        </Box>
      </DialogActions>
      <ConfirmDialog />
    </Dialog>
  );
};

export default EditPositionDialog;
