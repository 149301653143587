import { FunctionComponent } from 'react';

import { Grid, FormControlLabel, Switch, TextField } from '@mui/material';

import { Position } from '../../types/entities';

interface Props {
  position: Position;
  onChange: (pos: Position) => void;
  p?: number | undefined;
}
const PositionScheduleForm: FunctionComponent<Props> = ({ position, onChange, p = 0 }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateForm = (field: keyof Position, value: any) => {
    const copy = {
      ...position,
      [field]: value
    } as Position;
    onChange(copy);
  };

  return (
    <Grid p={p} container width="100%" spacing={2} sx={{ boxSizing: 'border-box' }}>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={
            <Switch
              sx={{ ml: 1 }}
              onChange={() => handleUpdateForm('useSmallProfit', !position.useSmallProfit)}
              checked={position.useSmallProfit}
            />
          }
          label="Use Small Profit"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          label="Hours Delayed"
          variant="outlined"
          type="number"
          value={position.smallProfitDelayHours}
          onChange={(e) => handleUpdateForm('smallProfitDelayHours', Number(e.target.value))}
          disabled={!position.useSmallProfit}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          label="Small Profit"
          variant="outlined"
          type="number"
          value={position.smallProfit}
          onChange={(e) => handleUpdateForm('smallProfit', Number(e.target.value))}
          disabled={!position.useSmallProfit}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={
            <Switch sx={{ ml: 1 }} onChange={() => handleUpdateForm('useTimeOut', !position.useTimeOut)} checked={position.useTimeOut} />
          }
          label="Use Time Out"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          size="small"
          label="Hours Delayed"
          variant="outlined"
          type="number"
          value={position.timeOutDelayHours}
          onChange={(e) => handleUpdateForm('timeOutDelayHours', Number(e.target.value))}
          disabled={!position.useTimeOut}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default PositionScheduleForm;
