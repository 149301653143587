import { ContractDetails, Security } from '../../types/entities';
import { SecCatergory } from '../../types/enums';

export interface UserSettingsState {
  useFrozen: boolean;
  ignoreMissingUnderMarketPrice: boolean;
  useSnapshot: boolean;
  newsPanelCollapsed: boolean;
  indicatorPanelCollapsed: boolean;
  hourGraphCollapsed: boolean;
  dailyGraphCollapsed: boolean;
  selectedSecCategory: SecCatergory;
  selectedSecurity: Security | undefined;
  selectedContractDetails?: ContractDetails | undefined;
  securityPanelCollapsed: boolean;
  favoritesPanelCollapsed: boolean;
  trendLinesCollapsed: boolean;
}

export const initialState: UserSettingsState = {
  useFrozen: false,
  ignoreMissingUnderMarketPrice: false,
  useSnapshot: false,
  newsPanelCollapsed: false,
  indicatorPanelCollapsed: false,
  hourGraphCollapsed: false,
  dailyGraphCollapsed: false,
  selectedSecCategory: SecCatergory.Commodity,
  selectedSecurity: undefined,
  securityPanelCollapsed: true,
  favoritesPanelCollapsed: false,
  trendLinesCollapsed: false
};
