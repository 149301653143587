import React, { FunctionComponent, useEffect } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useAppDispatch, useAppSelector } from './components/hooks/redux-hooks';
import Footer from './components/panels/Footer';
import Header from './components/panels/Header';
import { getRoutes } from './routes';
import { validate } from './store/auth/service';
import { getFavoritesAsync } from './store/favorite-contract/service';

const App: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const { loaded: authLoaded, token } = useAppSelector((gs) => gs.authState);
  const isAuthenticated = !!token;
  useEffect(() => {
    if (!authLoaded) {
      dispatch(validate());
    }
  }, []);

  // load favorites; data uses for multiple screens
  const { loaded: favoritesLoaded } = useAppSelector((gs) => gs.favoriteContractState);
  useEffect(() => {
    if (isAuthenticated && !favoritesLoaded) {
      dispatch(getFavoritesAsync());
    }
  }, [isAuthenticated]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {authLoaded && (
        <>
          {isAuthenticated && <Header />}
          {getRoutes(isAuthenticated)}
          {isAuthenticated && <Footer />}
        </>
      )}
    </LocalizationProvider>
  );
};

export default App;
