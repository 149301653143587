import { FunctionComponent, useState } from 'react';

import { Box, Card, Container, Skeleton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from '../../components/hooks/redux-hooks';
import ReloadDashboardFab from '../../components/ui/ReloadDashboardFab';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import SimpleWizardNav from '../../components/wizard/SimpleWizardNav';
import { getOrderSummaryBySymbolState } from '../../store/order-summary/selectors';
import { Position } from '../../types/entities';

import ExistingPositionTable from './ExistingPositionsTable';
import OrderSelectedPanel from './OrderSelectedPanel';
import PositionOrderTable from './OrderTable/OrderTable';
import PositionGroupPicker from './PositionGroupPicker';

const OrderSummaryPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const symbol = params.symbol || '';

  const orderSummaryState = useAppSelector((x) => x.orderSummaryState);
  const { groupId, orders } = getOrderSummaryBySymbolState(orderSummaryState, symbol || '');

  const { loading } = useAppSelector((gs) => gs.dashboardState);

  const [positionSelected, setPositionSelected] = useState<Position | undefined>();

  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Order Summary
        </Typography>
      </Container>
      <ResponsiveContainer>
        <Card>
          <Typography variant="h5" component="div" m={2}>
            Orders
          </Typography>
          <Box sx={{ pb: 2, px: 2 }}>
            <PositionGroupPicker symbol={symbol} groupId={groupId} />
          </Box>
          {orders.length > 0 ? (
            <Box mb={1}>
              <PositionOrderTable orders={orders} groupId={groupId} onRowClick={setPositionSelected} orderSelected={positionSelected} />
            </Box>
          ) : (
            <Box sx={{ ml: 2, mb: 2 }}>
              <small>No Orders..</small>
            </Box>
          )}
        </Card>
        <Card sx={{ mt: 2 }}>
          <Typography variant="h5" component="div" m={2}>
            Existing Positions
          </Typography>
          {loading && <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={80} />}
          {!loading && (
            <Box sx={{ mb: 1 }}>
              <ExistingPositionTable orders={orders} symbol={symbol} onPositionSelected={setPositionSelected} />
            </Box>
          )}
        </Card>
        <OrderSelectedPanel order={positionSelected} />
      </ResponsiveContainer>
      <Container>
        <SimpleWizardNav activeStep={2} steps={3} previous={() => navigate('/order')} />
      </Container>
      <ReloadDashboardFab />
    </>
  );
};

export default OrderSummaryPage;
