import { FunctionComponent, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import CustomCheckboxContol from '../../components/form-controls/CustomCheckBoxControl';
import { useAppDispatch, useAppSelector } from '../../components/hooks/redux-hooks';
import { setIgnoreMissingUnderMarketPrice, setUseFrozen, setUseSnapshot } from '../../store/user-settings/reducer';

const UserSettingsPanel: FunctionComponent = () => {
  const { useFrozen, ignoreMissingUnderMarketPrice, useSnapshot } = useAppSelector((gs) => gs.userSettingsState);
  const dispatch = useAppDispatch();
  const setUseFrozenHandler = () => {
    dispatch(setUseFrozen(!useFrozen));
  };
  const toggleIgnoreMissingUnderMarketPrice = () => {
    dispatch(setIgnoreMissingUnderMarketPrice(!ignoreMissingUnderMarketPrice));
  };
  const setUseSnapshotHandler = () => {
    dispatch(setUseSnapshot(!useSnapshot));
  };

  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion sx={{ my: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6">Market Data</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'inherit' }}>
          <CustomCheckboxContol
            label="Get Frozen Results (for closed market)"
            isChecked={useFrozen}
            setIsChecked={setUseFrozenHandler}
            small
          />
          <CustomCheckboxContol
            label="Ignore Missing Under Market Price (response-time efficient)"
            isChecked={ignoreMissingUnderMarketPrice}
            setIsChecked={toggleIgnoreMissingUnderMarketPrice}
            small
          />
          <CustomCheckboxContol
            label="Use Snapshots (11s) (instead of open-stream)"
            isChecked={useSnapshot}
            setIsChecked={setUseSnapshotHandler}
            small
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default UserSettingsPanel;
