import { FunctionComponent, useMemo, useState } from 'react';

import { Box, Button } from '@mui/material';

import { Security } from '../../types/entities';
import { SecCatergory } from '../../types/enums';
import SecurityCategoryTabs from '../ui/SecurityCategoryTabs';

interface Props {
  securities: Security[];
  selected: Security | undefined;
  onSelected: (security: Security | undefined) => void;
}

const SelectSecurityPanel: FunctionComponent<Props> = ({ securities, selected, onSelected }: Props) => {
  const selectedSymbol = selected?.symbol;

  const handleClick = (sec: Security) => {
    if (sec.symbol === selectedSymbol) {
      onSelected(undefined);
    } else {
      onSelected(sec);
    }
  };

  const [selectedCategory, setSelectedCategory] = useState(SecCatergory.Commodity);

  const filteredSecurities = useMemo(() => {
    switch (selectedCategory) {
      case SecCatergory.Commodity:
        return securities.filter((x) => x.category === 'Commodity');
      case SecCatergory.MicroCommodity:
        return securities.filter((x) => x.category === 'Micro');
      case SecCatergory.Stock:
        return securities.filter((x) => x.category === 'Stock');
      default:
        return [];
    }
  }, [selectedCategory]);

  return (
    <>
      <SecurityCategoryTabs onCategoryChanged={setSelectedCategory} />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 3 }}>
        {filteredSecurities.map((x) => (
          <Button key={x.symbol} onClick={() => handleClick(x)} variant={x.symbol === selectedSymbol ? 'contained' : 'outlined'}>
            {x.name}
          </Button>
        ))}
      </Box>
    </>
  );
};

export default SelectSecurityPanel;
