import React, { FunctionComponent } from 'react';

import { Box, Container, Tab, Tabs } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CustomTabPanel } from '../../components/ui/CustomTabPanel';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';

import { CashOverviewPanel } from './CashOverviewPanel';
import PositionsTab from './PositionsTab';
import WatchlistTab from './WatchlistTab';

const Dashboard: FunctionComponent = () => {
  const toTabIndex = (tabValue: string) => {
    switch (tabValue.toLowerCase()) {
      case 'watchlist':
        return 1;
      default:
        return 0;
    }
  };

  const fromTabIndex = (index: number) => {
    switch (index) {
      case 1:
        return 'watchlist';
      default:
        return 'positions';
    }
  };

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get('tab') ?? '';
  const tabIndex = toTabIndex(selectedTab);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    // setValue(newValue);
    const tabValue = fromTabIndex(newValue);
    navigate(`?tab=${tabValue}`);
  };

  return (
    <div>
      <Container>
        <CashOverviewPanel />
      </Container>
      <ResponsiveContainer>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              sx={{
                '& .MuiTabs-flexContainer': {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Tab label="Positions" />
              <Tab label="Watchlist" />
            </Tabs>
          </Box>
        </Box>
      </ResponsiveContainer>
      <ResponsiveContainer>
        <CustomTabPanel value={tabIndex} index={0}>
          <PositionsTab />
        </CustomTabPanel>
        <CustomTabPanel value={tabIndex} index={1}>
          <WatchlistTab />
        </CustomTabPanel>
      </ResponsiveContainer>
    </div>
  );
};

export default Dashboard;
