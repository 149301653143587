import { FunctionComponent, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Tooltip } from '@mui/material';

import { FundamentalLink, Security } from '../../../types/entities';

import EditNewsLinkDialog from './EditNewsLinkDialog';

interface Props {
  security?: Security | undefined;
}

const AddNewsLinkButton: FunctionComponent<Props> = ({ security }: Props) => {
  const symbol = security?.parentSymbol || security?.symbol;

  const [selectedLink, setSelectedLink] = useState<FundamentalLink | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const handleAddLinkBtnClick = () => {
    setSelectedLink(undefined);
    setShowEditDialog(true);
  };

  return (
    <>
      <Tooltip title="Add News Link" placement="left-start">
        <Button size="small" color="primary" variant="outlined" onClick={handleAddLinkBtnClick}>
          <AddIcon />
        </Button>
      </Tooltip>

      {showEditDialog && <EditNewsLinkDialog symbol={symbol} link={selectedLink} isOpen={showEditDialog} onClose={closeDialog} />}
    </>
  );
};

export default AddNewsLinkButton;
