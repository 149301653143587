import { FunctionComponent } from 'react';

import { Alert, Box, Button, Card } from '@mui/material';

import TradePresetForm from '../../../../components/forms/TradePresetForm';
import { useAppDispatch, useAppSelector } from '../../../../components/hooks/redux-hooks';
import Spinner from '../../../../components/ui/Spinner';
import { setTradePreset } from '../../../../store/order-option/reducer';
import { placeEntryOrderAsync } from '../../../../store/order-option/service';
import { OptionContract, TradePreset } from '../../../../types/entities';
import { OrderStatusType } from '../../../../types/enums';
import { createOptionPosition } from '../../../../utils/position-utils';

import OptionOrderTable from './OptionOrderTable';

const PlaceOrderStep: FunctionComponent = () => {
  const orderOptionState = useAppSelector((gs) => gs.orderOptionState);
  const { entryPrice, marketPrice, orderType, orderAction, orderSize, loading, error, entryOrderReport, optionRight, tradePreset } =
    orderOptionState;
  const contractOption = orderOptionState.option as OptionContract;

  const dispatch = useAppDispatch();

  const handlePlaceOrder = () => {
    const pos = createOptionPosition(contractOption, optionRight, orderAction, orderType, orderSize, entryPrice, marketPrice, tradePreset);
    dispatch(placeEntryOrderAsync(pos));
  };

  let alertPanel: JSX.Element | undefined;
  if (error) {
    alertPanel = (
      <Alert sx={{ mt: 2 }} severity="error">
        {error.toString()}
      </Alert>
    );
  }

  let orderStatusOk = false;
  if (!!entryOrderReport) {
    orderStatusOk =
      entryOrderReport.status === OrderStatusType.Filled ||
      (orderType === 'LMT' && entryOrderReport.status === OrderStatusType.PreSubmitted);
  }

  let warningPanel: JSX.Element | undefined;
  if (!!entryOrderReport && !orderStatusOk) {
    warningPanel = (
      <Alert sx={{ mt: 2 }} severity="warning">
        Place Order Report Status: {OrderStatusType[entryOrderReport.status]}
      </Alert>
    );
  }

  let successPanel: JSX.Element | undefined;
  if (!!entryOrderReport && orderStatusOk) {
    successPanel = (
      <Alert sx={{ mt: 2 }} severity="success">
        Order Filled Successfully!
      </Alert>
    );
  }

  const handleTradePresetChanged = (tp: TradePreset) => dispatch(setTradePreset(tp));

  return (
    <Box>
      <Spinner loading={loading} />
      <Card sx={{ mt: 3, p: 2 }}>
        <TradePresetForm tradePreset={tradePreset} onChange={handleTradePresetChanged} />
      </Card>
      <Card sx={{ mt: 3, p: 2 }}>
        <OptionOrderTable />
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="success" onClick={handlePlaceOrder} disabled={loading}>
            Place Order
          </Button>
        </Box>
        {alertPanel}
        {warningPanel}
        {successPanel}
      </Card>
    </Box>
  );
};

export default PlaceOrderStep;
