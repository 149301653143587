import { FunctionComponent } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useAppSelector } from '../../../components/hooks/redux-hooks';
import { Position } from '../../../types/entities';

import OrderRow from './OrderRow';

interface Props {
  orders: Position[];
  groupId: string | undefined;
  orderSelected: Position | undefined;
  onRowClick: (pos: Position | undefined) => void;
}

const OrderTable: FunctionComponent<Props> = ({ orders, groupId, orderSelected, onRowClick }: Props) => {
  const { positions } = useAppSelector((gs) => gs.positionState);
  const { orders: openOrders } = useAppSelector((gs) => gs.openOrderState);

  const hasPosition = (order: Position) => {
    return positions.find((x) => x.conId === order.conId && !x.completed && x.size != 0) !== undefined;
  };

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Sec Type</TableCell>
        <TableCell>Parent</TableCell>
        <TableCell>Local</TableCell>
        <TableCell align="center">Size</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Action</TableCell>
        <TableCell align="center">Right</TableCell>
        {/* <TableCell align="right">Entry Price</TableCell> */}
        <TableCell align="right">Price</TableCell>
        <TableCell align="right">Premium</TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );

  const orderRows = orders.map((pos: Position) => {
    const openOrder = openOrders.find((x) => x.conId === pos.conId);
    const isBtnDisabled = hasPosition(pos) || !!openOrder;
    return (
      <OrderRow
        key={pos.conId}
        order={pos}
        groupId={groupId}
        orderDisabled={isBtnDisabled}
        orderSelected={orderSelected}
        onRowClick={onRowClick}
        openOrder={openOrder}
      />
    );
  });

  return (
    <TableContainer>
      <Table size="small">
        {tableHeader}
        <TableBody>{orderRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderTable;
