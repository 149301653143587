import { FunctionComponent, useState } from 'react';

import PosGroupIcon from '@mui/icons-material/CreateNewFolder';
import { Box, Button, Skeleton, Tooltip, Typography } from '@mui/material';

import PositionGroupDialog from '../../../components/dialogs/PositionGroupDialog';
import { useAppSelector } from '../../../components/hooks/redux-hooks';
import GutterBox from '../../../components/ui/GutterBox';
import ReloadDashboardFab from '../../../components/ui/ReloadDashboardFab';

import FundamentalsPanel from './FundamentalsPanel';
import { mapSortedGrouplessPositionPanels, mapSortedPositionGroupPanels } from './utils';

const PositionsTab: FunctionComponent = () => {
  const { loaded: initialLoaded } = useAppSelector((gs) => gs.accountInfoState);

  const { groups } = useAppSelector((gs) => gs.positionGroupState);
  const groupIds = groups.map((x) => x.id);

  const { positions } = useAppSelector((gs) => gs.positionState);
  const activePositions = positions.filter((x) => !x.completed);
  const grouplessPositions = activePositions.filter((x) => !groupIds.includes(x.groupId));

  const [showPositionGroupDialog, setShowPositionGroupDialog] = useState(false);
  const handleAddGroupBtnClick = () => setShowPositionGroupDialog(true);
  const closeShowPositionGroupDialog = () => setShowPositionGroupDialog(false);

  return (
    <Box>
      <GutterBox sx={{ my: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Positions</Typography>
        <Tooltip title="Add Position Group" placement="left-start">
          <Button size="small" variant="outlined" color="primary" onClick={handleAddGroupBtnClick}>
            <PosGroupIcon />
          </Button>
        </Tooltip>
      </GutterBox>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {mapSortedPositionGroupPanels(groups)}
        {mapSortedGrouplessPositionPanels(grouplessPositions)}
      </Box>
      {!initialLoaded && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Skeleton variant="rounded" animation="wave" height={60} />
          <Skeleton variant="rounded" animation="wave" height={60} />
          <Skeleton variant="rounded" animation="wave" height={60} />
        </Box>
      )}
      <Box mt={3}>
        <FundamentalsPanel />
      </Box>

      <PositionGroupDialog isOpen={showPositionGroupDialog} onClose={closeShowPositionGroupDialog} />
      <ReloadDashboardFab />
    </Box>
  );
};

export default PositionsTab;
