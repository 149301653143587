import { toast } from 'react-toastify';

import { fetchMarketData } from '../../../api';
import { ContractMarketData, MarketDataParams, OpenOrderReport, Position } from '../../../types/entities';

export const getPremiumColor = (pos: Position) => {
  if (pos.premium == 0) {
    return 'Inherit';
  }
  return pos.orderAction === 'BUY' ? '#e52b50' : '#00a86b';
};

export const getMarketDataAsync = (order: Position, useFrozen: boolean, pricesOnly = true) => {
  const params: MarketDataParams = {
    conId: order.conId,
    symbol: order.symbol,
    localSymbol: order.localSymbol,
    exchange: order.exchange,
    currency: 'USD',
    secType: order.secType,
    right: order.right,
    strike: order.strikePrice,
    lastTradeDateOrContractMonth: order.lastTradeDateOrContractMonth,
    multiplier: order.multiplier,
    noIndicators: true,
    pricesOnly,
    useFrozen
  };

  const promise = new Promise<ContractMarketData>((resolve, reject) => {
    fetchMarketData(params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        toast.error(msg);
        reject();
      });
  });

  return promise;
};

export const orderOrPositionExists = (positions: Position[], openOrders: OpenOrderReport[], order: Position) => {
  const position = positions.find((x) => x.conId === order.conId && !x.completed && x.size != 0);
  if (position) {
    return true;
  }
  const openOrder = openOrders.find((x) => x.conId === order.conId);
  return !!openOrder;
};

export const getMarketPrice = (marketData: ContractMarketData) => {
  if (marketData.lastPrice) {
    return marketData.lastPrice;
  }
  const sum = marketData.askPrice + marketData.bidPrice;
  if (sum !== 0) {
    return sum / 2;
  }
  return undefined;
};
