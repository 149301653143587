import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContractDetails, Security } from '../../types/entities';
import { SecCatergory } from '../../types/enums';

import { UserSettingsState, initialState } from './state';

const userSlice = createSlice({
  name: 'user-settings',
  initialState,
  reducers: {
    setUseFrozen: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.useFrozen = action.payload;
    },
    setIgnoreMissingUnderMarketPrice: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.ignoreMissingUnderMarketPrice = action.payload;
    },
    setUseSnapshot: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.useSnapshot = action.payload;
    },
    setNewsPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.newsPanelCollapsed = action.payload;
    },
    setIndicatorPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.indicatorPanelCollapsed = action.payload;
    },
    setHourGraphCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.hourGraphCollapsed = action.payload;
    },
    setDailyGraphCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.dailyGraphCollapsed = action.payload;
    },
    setSelectedCategory: (state: UserSettingsState, action: PayloadAction<SecCatergory>) => {
      state.selectedSecCategory = action.payload;
    },
    setSelectedContractDetails: (state: UserSettingsState, action: PayloadAction<ContractDetails | undefined>) => {
      state.selectedContractDetails = action.payload;
    },
    setSecurityPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.securityPanelCollapsed = action.payload;
    },
    setFavoritesPanelCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.favoritesPanelCollapsed = action.payload;
    },
    setSelectedSecurity: (state: UserSettingsState, action: PayloadAction<Security | undefined>) => {
      state.selectedSecurity = action.payload;
    },
    setTrendLinesCollapsed: (state: UserSettingsState, action: PayloadAction<boolean>) => {
      state.trendLinesCollapsed = action.payload;
    }
  }
});

const { actions, reducer } = userSlice;
export const {
  setUseFrozen,
  setIgnoreMissingUnderMarketPrice,
  setUseSnapshot,
  setNewsPanelCollapsed,
  setIndicatorPanelCollapsed,
  setHourGraphCollapsed,
  setDailyGraphCollapsed,
  setSelectedCategory,
  setSelectedSecurity,
  setSelectedContractDetails,
  setSecurityPanelCollapsed,
  setFavoritesPanelCollapsed,
  setTrendLinesCollapsed
} = actions;
export default reducer;
