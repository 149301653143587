import { FunctionComponent } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Theme } from '@mui/material';

interface Props {
  onClose: () => void;
}

const TopRightCloseButton: FunctionComponent<Props> = ({ onClose }: Props) => {
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme: Theme) => theme.palette.grey[500]
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default TopRightCloseButton;
