import { FunctionComponent, useMemo, useState } from 'react';

import ScheduleIcon from '@mui/icons-material/Schedule';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { IconButton, Tooltip, useTheme } from '@mui/material';

import racingFlagsIcon from '../../assets/svg/racing-flags.svg';
import { Position } from '../../types/entities';
import TimelineDialog from '../dialogs/TimelineDialog';
import { useAppSelector } from '../hooks/redux-hooks';

interface Props {
  position: Position;
  size?: 'small' | undefined;
}

const TimelineIconButton: FunctionComponent<Props> = ({ position, size }: Props) => {
  const { expirationDate: expires } = position;
  const hasActiveSchedule = position.useSmallProfit || position.useTimeOut;

  const theme = useTheme();

  const openOrderState = useAppSelector((gs) => gs.openOrderState);
  const openOrder = openOrderState.orders.find((x) => x.conId === position.conId);
  const openOrderSize = openOrder?.size || 0;

  const [showScheduleDialog, setShowScheduleDialog] = useState(false);
  const handleScheduleBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowScheduleDialog(true);
  };
  const closeScheduleDialog = () => setShowScheduleDialog(false);

  const hasPositionExpired = useMemo(() => {
    if (!!expires) {
      const now = new Date();
      const exDt = new Date(expires);
      return now > exDt;
    }
  }, [expires]);

  const timelineBtn = useMemo(() => {
    if (hasPositionExpired) {
      return (
        <Tooltip title="Timeline : Expired" placement="top">
          <IconButton size="small" onClick={handleScheduleBtnClick}>
            <img src={racingFlagsIcon} width="24px" alt="Finished" />
          </IconButton>
        </Tooltip>
      );
    }

    const isPositionClosed = openOrderSize === 0 && position.size === 0;
    if (isPositionClosed) {
      return (
        <Tooltip title="Timeline : Ended" placement="top">
          <IconButton size="small" onClick={handleScheduleBtnClick}>
            <img src={racingFlagsIcon} width="24px" alt="Finished" />
          </IconButton>
        </Tooltip>
      );
    }

    let color = theme.palette.grey[200];
    if (position.isMarketClosed) {
      color = '#ffe135';
    } else if (hasActiveSchedule) {
      color = theme.palette.primary.main;
    }

    const sx = size === 'small' ? { fontSize: 20, mx: 0.5 } : {};
    const icon = position.isMarketClosed ? <ScheduleIcon sx={sx} /> : <TimelapseIcon sx={sx} />;

    return (
      <Tooltip title={position.isMarketClosed ? 'Timeline: Market Closed' : 'Timeline'} placement="top">
        <IconButton size="small" onClick={handleScheduleBtnClick} sx={{ color }}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  }, [position, hasPositionExpired]);

  return (
    <>
      {timelineBtn}
      <TimelineDialog isOpen={showScheduleDialog} onClose={closeScheduleDialog} position={position} />
    </>
  );
};

export default TimelineIconButton;
