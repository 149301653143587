import { FunctionComponent, useEffect } from 'react';

import { Box, Divider, Skeleton, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../../components/hooks/redux-hooks';
import NewsTable from '../../../../components/panels/NewsPanel/NewsTable';
import AccordionWrapper from '../../../../components/ui/AccordionWrapper';
import { getFundamentalNotesAsync } from '../../../../store/fundamental-note/service';
import { setNewsPanelCollapsed } from '../../../../store/user-settings/reducer';

import SeasonalAccordion from './SeasonalAccordion';

const FundamentalsPanel: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const { securities } = useAppSelector((gs) => gs.securityState);
  const { newsPanelCollapsed } = useAppSelector((gs) => gs.userSettingsState);

  const toggleNewsPanelCollapsed = (_e: React.SyntheticEvent<Element, Event>, expanded: boolean) =>
    dispatch(setNewsPanelCollapsed(!expanded));

  const { loading, loaded, notes } = useAppSelector((gs) => gs.fundamentalNoteState);

  useEffect(() => {
    if (!loaded) {
      dispatch(getFundamentalNotesAsync());
    }
  }, []);

  return (
    <Box>
      <Divider>
        <Typography variant="caption" sx={{ color: '#ccc' }}>
          FUNDAMENTALS
        </Typography>
      </Divider>

      <Box mt={3}>
        <AccordionWrapper title="News" collapsed={newsPanelCollapsed} onChange={toggleNewsPanelCollapsed}>
          <NewsTable />
        </AccordionWrapper>
        {loading ? (
          <Skeleton variant="rounded" animation="wave" height={80} />
        ) : (
          <SeasonalAccordion securities={securities} notes={notes} />
        )}
      </Box>
    </Box>
  );
};

export default FundamentalsPanel;
