import * as React from 'react';

import { TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { OptionContract, OrderAction } from '../../../types/entities';
import { themeColors } from '../../../utils/color-utils';

interface Props {
  option: OptionContract;
  orderAction: OrderAction;
}

export default function VolatilityDetailsTable({ option, orderAction }: Props) {
  const { impliedVolatility, priceDiff, priceDiffPct, delta, deltaPct, standardDeviation, standardDeviationAmount, empericPct } = option;

  const originalEmpericValue = 100 - empericPct;
  const empericValue = orderAction === 'BUY' ? 100 - empericPct : empericPct;
  const deltaValue = orderAction === 'BUY' ? 100 - deltaPct : deltaPct;
  const impliedVolatilityPct = impliedVolatility * 100;

  const getPriceDiffPctColor = () => {
    if (orderAction === '' || priceDiffPct === impliedVolatilityPct) {
      return 'Inherit';
    }
    if (orderAction === 'BUY') {
      return priceDiffPct < impliedVolatilityPct ? themeColors.indicatorGreen : themeColors.indicatorRed;
    } else {
      return priceDiffPct > impliedVolatilityPct ? themeColors.indicatorGreen : themeColors.indicatorRed;
    }
  };

  const getEmpericRuleColor = () => {
    if (empericValue === 50) {
      return 'Inherit';
    }
    return empericValue > 50 ? themeColors.indicatorGreen : themeColors.indicatorRed;
  };

  const getDeltaColor = () => {
    if (deltaValue === 50) {
      return 'Inherit';
    }
    return deltaValue > 50 ? themeColors.indicatorGreen : themeColors.indicatorRed;
  };

  const impliedVltPctLabel = `${impliedVolatilityPct.toFixed(2)}%`;

  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">SD</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="right">Vlt.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              Implied
            </TableCell>
            <TableCell align="right">1.00</TableCell>
            <TableCell align="right">${standardDeviationAmount.toFixed(2)}</TableCell>
            <TableCell title="Implied Volatility" align="right">
              <span>{impliedVltPctLabel}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              At Strike
            </TableCell>
            <TableCell align="right">{standardDeviation.toFixed(2)}</TableCell>
            <TableCell title="Expires" align="right">
              <span>${priceDiff.toFixed(2)}</span>
            </TableCell>
            <TableCell align="right">
              <span style={{ color: getPriceDiffPctColor() }}>{priceDiffPct.toFixed(2)}%</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              Emperic Rule
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell title="Emperic" align="right">
              {originalEmpericValue.toFixed(1)}%
            </TableCell>
            <TableCell title="Inverted Emperic" align="right">
              <span style={{ color: getEmpericRuleColor() }}>{empericValue.toFixed(1)}%</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>
              Delta
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell title="Delta" align="right">
              {delta.toFixed(2)}
            </TableCell>
            <TableCell title="Inverted Emperic" align="right">
              <span style={{ color: getDeltaColor() }}>{deltaValue.toFixed(2)}%</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
