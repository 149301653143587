import { FunctionComponent, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import {
  createFundamentalLinkAsync,
  removeFundamentalLinkAsync,
  updateFundamentalLinkAsync
} from '../../../store/fundamental-link/service';
import { FundamentalLink } from '../../../types/entities';
import { FundamentalLinkType } from '../../../types/enums';
import { useAppDispatch } from '../../hooks/redux-hooks';

interface Props {
  symbol?: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  link?: FundamentalLink;
}

const EditNewsLinkDialog: FunctionComponent<Props> = ({ symbol, isOpen, onClose, link }: Props) => {
  const linkTemplate: FundamentalLink = {
    id: '',
    symbol,
    title: '',
    url: '',
    type: symbol ? FundamentalLinkType.SecuritySpecific : FundamentalLinkType.General,
    sortOrder: 0
  };

  const [linkForm, setLinkForm] = useState<FundamentalLink>(link || linkTemplate);

  useEffect(() => {
    if (link) {
      setLinkForm(link);
    } else {
      setLinkForm(linkTemplate);
    }
  }, [link]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateNoteForm = (field: keyof FundamentalLink, value: any) => {
    const copy = {
      ...linkForm,
      [field]: value
    } as FundamentalLink;
    setLinkForm(copy);
  };

  const dispatch = useAppDispatch();

  const handleSaveLink = () => {
    const isNewLink = !link;
    if (isNewLink) {
      dispatch(createFundamentalLinkAsync(linkForm));
    } else {
      dispatch(updateFundamentalLinkAsync(linkForm));
    }
    onClose();
  };

  const handleRemoveLink = () => {
    const isNewLink = !link;
    if (!isNewLink) {
      dispatch(removeFundamentalLinkAsync(linkForm));
    }
    onClose();
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <Typography variant="h6">Fundamental Link</Typography>
        </Box>

        <FormControl>
          <TextField
            name="title"
            size="small"
            label="Label"
            variant="outlined"
            type="text"
            value={linkForm.title || ''}
            onChange={(e) => updateNoteForm('title', e.target.value)}
          />
        </FormControl>

        <FormControl>
          <TextField
            name="url"
            size="small"
            label="URL"
            variant="outlined"
            type="text"
            value={linkForm.url || ''}
            onChange={(e) => updateNoteForm('url', e.target.value)}
          />
        </FormControl>

        <FormControl>
          <TextField
            name="tooltip"
            size="small"
            label="Keywords"
            variant="outlined"
            type="text"
            value={linkForm.tooltip || ''}
            onChange={(e) => updateNoteForm('tooltip', e.target.value)}
          />
        </FormControl>
        <FormControl>
          <TextField
            name="sortOrder"
            size="small"
            label="Sort Order"
            variant="outlined"
            type="number"
            value={linkForm.sortOrder}
            onChange={(e) => updateNoteForm('sortOrder', parseInt(e.target.value))}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="security-label" size="small">
            Type (read only)
          </InputLabel>
          <Select
            name="type"
            labelId="type"
            value={linkForm.type}
            label="Type (read only)"
            onChange={(e) => updateNoteForm('type', e.target.value)}
            size="small"
            readOnly
          >
            <MenuItem value={FundamentalLinkType.SecuritySpecific}>Security Specific</MenuItem>
            <MenuItem value={FundamentalLinkType.General}>General</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {link && (
          <Box sx={{ display: 'flex', gap: 1, flexGrow: 1, justifyContent: 'flex-start' }}>
            <Button variant="contained" color="error" onClick={handleRemoveLink}>
              Remove
            </Button>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={handleSaveLink}>
            Save
          </Button>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditNewsLinkDialog;
