import React, { FunctionComponent } from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';

import { OrderAction, OrderType } from '../../types/entities';

interface Props {
  orderType: OrderType;
  onOrderTypeChanged: (ot: OrderType) => void;
  orderAction: OrderAction;
  onOrderActionChanged: (oa: OrderAction) => void;
  marketPrice: number;
  entryPrice: number;
  onEntryPriceChanged: (e: number) => void;
}

const OrderTypePicker: FunctionComponent<Props> = ({
  orderType,
  onOrderTypeChanged,
  orderAction,
  onOrderActionChanged,
  marketPrice,
  entryPrice,
  onEntryPriceChanged
}) => {
  const handleEntryPriceChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const price = Number(e.target.value);
    onEntryPriceChanged(price);
  };

  const priceLabel = orderType === 'LMT' ? 'Limit Price' : orderType === 'STP' ? 'Stop Price' : 'Market Price';

  let customPriceErrorMessage: string | undefined;
  if (marketPrice) {
    if (orderType === 'LMT') {
      if (orderAction === 'BUY' && entryPrice > marketPrice) {
        customPriceErrorMessage = 'BUY-LMT (implies buy-in low) above market price will trigger instantly.';
      } else if (orderAction === 'SELL' && entryPrice < marketPrice) {
        customPriceErrorMessage = 'SELL-LMT (implies sell-in high) below market price will trigger instantly.';
      }
    } else if (orderType === 'STP') {
      if (orderAction === 'BUY' && entryPrice < marketPrice) {
        customPriceErrorMessage = 'BUY-STP (implies a short position) below market price will trigger instantly.';
      } else if (orderAction === 'SELL' && entryPrice > marketPrice) {
        customPriceErrorMessage = 'SELL-STP (implies a long position) above market price will trigger instantly.';
      }
    }
  }

  return (
    <Box sx={{ p: 2 }}>
      <Box>
        <Typography variant="h6" sx={{ mb: 2 }} component="div">
          Underlying Type
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6} lg={4}>
            <ButtonGroup variant="outlined" disableElevation fullWidth>
              <Button onClick={() => onOrderActionChanged('BUY')} variant={orderAction === 'BUY' ? 'contained' : 'outlined'}>
                Buy
              </Button>
              <Button onClick={() => onOrderActionChanged('SELL')} variant={orderAction === 'SELL' ? 'contained' : 'outlined'}>
                Sell
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ButtonGroup variant="outlined" fullWidth>
              <Button onClick={() => onOrderTypeChanged('MKT')} variant={orderType === 'MKT' ? 'contained' : 'outlined'}>
                Market
              </Button>
              <Button onClick={() => onOrderTypeChanged('LMT')} variant={orderType === 'LMT' ? 'contained' : 'outlined'}>
                Limit
              </Button>
              <Button onClick={() => onOrderTypeChanged('STP')} variant={orderType === 'STP' ? 'contained' : 'outlined'}>
                Stop
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl sx={{ width: '100%' }} error={!!customPriceErrorMessage}>
              <InputLabel htmlFor="outlined-adornment-price">{priceLabel}</InputLabel>
              <OutlinedInput
                value={entryPrice}
                onChange={handleEntryPriceChanged}
                size="small"
                type="number"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label={priceLabel}
                disabled={orderType === 'MKT'}
              />
              {customPriceErrorMessage && <FormHelperText>{customPriceErrorMessage}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderTypePicker;
