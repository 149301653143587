export const POSTION_ZEBRA_COLOR_EVEN = 'rgb(26, 58, 86)';
export const POSTION_ZEBRA_COLOR_ODD = 'rgb(39, 39, 39)';

export const PUTCALL_RATIO_BEAR_THRESHOLD = 1.0;
export const PUTCALL_RATIO_BULL_THRESHOLD = 0.7;

export const GRAPH_PAGE_DAILY_CHART_ID = 1;
export const GRAPH_PAGE_HOUR_CHART_ID = 2;
export const RESEARCH_PAGE_DAILY_CHART_ID = 3;
export const RESEARCH_PAGE_HOUR_CHART_ID = 4;
export const ORDER_PAGE_DAILY_CHART_ID = 5;
export const ORDER_SUMMARY_PAGE_DAILY_CHART_ID = 6;

export const KEYCODE_R = 82;
