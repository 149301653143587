import { FunctionComponent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Tooltip, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../components/hooks/redux-hooks';
import NewsPanel from '../../../components/panels/NewsPanel';
import SelectSecurityPanel from '../../../components/panels/SelectSecurityPanel';
import Spinner from '../../../components/ui/Spinner';
import { getFundamentalNotesAsync } from '../../../store/fundamental-note/service';
import { FundamentalNote, Security } from '../../../types/entities';

import FundamentalNoteDialog from './FundamentalNoteDialog';
import FundamentalNotePanel from './FundamentalNotePanel';

const FundamentalsTab: FunctionComponent = () => {
  const { loading, loaded, notes } = useAppSelector((gs) => gs.fundamentalNoteState);
  const { securities } = useAppSelector((gs) => gs.securityState);
  const [security, setSecurity] = useState<Security | undefined>();

  const [expanded, setExpanded] = useState(true);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState<FundamentalNote | undefined>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && !loaded) {
      dispatch(getFundamentalNotesAsync());
    }
  });

  const openDialog = (note?: FundamentalNote) => {
    setSelectedNote(note);
    setShowEditDialog(true);
  };
  const closeDialog = () => setShowEditDialog(false);

  const securityNotes = !!security ? notes.filter((x) => x.symbol === security.symbol) : [];

  const fundamentalNoteHeader = !!security ? (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', m: 2 }}>
      <Typography variant="h6">{`${security.name} - Fundamental Notes`}</Typography>
      <Tooltip title="Add Fundamental Note" placement="left-start">
        <Button size="small" variant="outlined" color="primary" onClick={() => openDialog()} title="Add">
          <AddIcon />
        </Button>
      </Tooltip>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', m: 2 }}>
      <Typography variant="h6">Fundamental Notes</Typography>
    </Box>
  );

  const fundamentalNotePanel =
    securityNotes.length > 0 ? (
      <FundamentalNotePanel notes={securityNotes} onOpenDialog={openDialog} />
    ) : (
      <Typography sx={{ m: 2 }} fontSize="smaller">
        No Fundamental Notes..
      </Typography>
    );

  return (
    <Box>
      <Spinner loading={loading} />

      <Accordion sx={{ my: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Select Security</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SelectSecurityPanel securities={securities} selected={security} onSelected={setSecurity} />
        </AccordionDetails>
      </Accordion>

      <Card sx={{ mb: 2 }}>
        <NewsPanel security={security} />
      </Card>

      <Card>
        {fundamentalNoteHeader}
        {!!security ? (
          fundamentalNotePanel
        ) : (
          <Typography sx={{ m: 2 }} fontSize="smaller">
            No security selected..
          </Typography>
        )}
      </Card>

      {security && <FundamentalNoteDialog security={security} isOpen={showEditDialog} onClose={closeDialog} note={selectedNote} />}
    </Box>
  );
};

export default FundamentalsTab;
