import * as React from 'react';
import { useState, useEffect } from 'react';

import { Alert, Box, Button, Card, FormControl, Input, InputLabel, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../components/hooks/redux-hooks';
import Spinner from '../../components/ui/Spinner';
import { authAsync } from '../../store/auth/service';
import { AuthState } from '../../store/auth/state';

import './styles.scss';

const Login: React.FunctionComponent = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [disableSumbit, setDisableSubmit] = useState(false);

  const authState: AuthState = useAppSelector((gs) => gs.authState);
  const loading = authState.loading;
  const error = authState.error;
  const attempts = authState.attempts;
  const isAuthenticated = !!authState.token;
  const authRedirectPath = authState.authRedirectPath;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const login = () => {
    if (disableSumbit) {
      return;
    }
    dispatch(authAsync(username, password));
  };

  const handleSubmit = (e: React.MouseEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>) => {
    login();
    e.preventDefault();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  useEffect(() => {
    if (attempts > 0) {
      setDisableSubmit(true);
      setTimeout(() => {
        setDisableSubmit(false);
      }, 1000 * attempts * attempts);
    }
  }, [attempts]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(authRedirectPath);
    }
  }, [isAuthenticated]);

  let alertPanel: JSX.Element | undefined;
  if (error) {
    alertPanel = (
      <Alert severity="error" sx={{ my: 1 }}>
        {error}
      </Alert>
    );
  }

  return (
    <div className="login">
      <Spinner loading={loading} />
      <Card sx={{ p: 2, bgcolor: 'rgba(19, 19, 19, 0.5)', width: '300px' }}>
        <Box
          onSubmit={handleSubmit}
          component="form"
          noValidate
          autoComplete="off"
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Typography variant="h4" sx={{ mt: 2, textAlign: 'center' }}>
            ORCASTRIKE
          </Typography>
          <FormControl>
            <InputLabel htmlFor="login-usr">Username</InputLabel>
            <Input id="login-usr" required value={username} onChange={(event) => setUsername(event.target.value)} />
          </FormControl>
          <FormControl sx={{ mb: 1 }}>
            <InputLabel htmlFor="login-pwd">Password</InputLabel>
            <Input
              id="login-pwd"
              required
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onKeyDown={(event) => handleKeyDown(event)}
            />
          </FormControl>
          {alertPanel}
          <Button
            type="submit"
            sx={{ mt: 1, mb: 3 }}
            variant="outlined"
            color={disableSumbit ? 'error' : 'primary'}
            disabled={disableSumbit}
          >
            Login
          </Button>
        </Box>
      </Card>
    </div>
  );
};

export default Login;
