import { FunctionComponent, useState, useEffect } from 'react';

import { Box, Button, ButtonGroup, Container, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../components/hooks/redux-hooks';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import Spinner from '../../components/ui/Spinner';
import { getTradeHistoryStateByDate } from '../../store/trade-history/selectors';
import { getTradeHistoryAsync } from '../../store/trade-history/service';
import { months } from '../../utils/month-utils';

import TradeHistoryTable from './TradeHistoryTable';

const TradeHistoryPage: FunctionComponent = () => {
  const now = new Date();
  const yearNow = now.getFullYear();
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [selectedMonth, setSelectedMonth] = useState(now.getMonth() + 1);

  const tradeHistoryState = useAppSelector((gs) => gs.tradeHistoryState);
  const { loading, loaded, positions } = getTradeHistoryStateByDate(tradeHistoryState, selectedYear, selectedMonth);

  const { securities } = useAppSelector((gs) => gs.securityState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loaded) {
      dispatch(getTradeHistoryAsync(selectedYear, selectedMonth));
    }
  }, [selectedYear, selectedMonth]);

  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ my: 3 }}>
          Trade History
        </Typography>
      </Container>
      <Container>
        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" component="div" sx={{ mb: 0.5 }}>
            SELECT YEAR
          </Typography>
          <ButtonGroup variant="outlined" disableElevation fullWidth>
            <Button onClick={() => setSelectedYear(yearNow - 2)} variant={selectedYear === yearNow - 2 ? 'contained' : 'outlined'}>
              {yearNow - 2}
            </Button>
            <Button onClick={() => setSelectedYear(yearNow - 1)} variant={selectedYear === yearNow - 1 ? 'contained' : 'outlined'}>
              {yearNow - 1}
            </Button>
            <Button onClick={() => setSelectedYear(yearNow)} variant={selectedYear === yearNow ? 'contained' : 'outlined'}>
              {yearNow}
            </Button>
          </ButtonGroup>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" component="div" sx={{ mb: 0.5 }}>
            SELECT MONTH
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {months.map((month, i) => (
              <Button key={i} onClick={() => setSelectedMonth(i + 1)} variant={selectedMonth === i + 1 ? 'contained' : 'outlined'}>
                {month}
              </Button>
            ))}
          </Box>
        </Box>
      </Container>
      <Spinner loading={loading} />
      <ResponsiveContainer>
        <TradeHistoryTable securities={securities} trades={positions} />
      </ResponsiveContainer>
    </>
  );
};

export default TradeHistoryPage;
