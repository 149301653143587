import { addFavoriteAsync, removeFavoriteAsync } from '../../store/favorite-contract/service';
import { ContractDetails, FavoriteContract } from '../../types/entities';

import { useAppDispatch, useAppSelector } from './redux-hooks';

const useToggleFavorite = (): [isFavorite: (details: ContractDetails) => boolean, toggleFavorite: (details: ContractDetails) => void] => {
  const { favorites } = useAppSelector((gs) => gs.favoriteContractState);

  const isFavorite = (details: ContractDetails): boolean => {
    const conId = details.contract.conId;
    const fav = favorites.find((x) => x.details.contract.conId === conId);
    return !!fav;
  };

  const { securities } = useAppSelector((gs) => gs.securityState);
  const getSecurity = (details: ContractDetails) => {
    const sec = securities.find((x) => x.symbol === details.contract.symbol);
    return sec ? sec.name : 'N/A';
  };

  const dispatch = useAppDispatch();

  const toggleFavorite = (details: ContractDetails) => {
    const favorite = favorites.find((x) => x.details.contract.conId === details.contract.conId);
    if (!!favorite) {
      dispatch(removeFavoriteAsync(favorite));
    } else {
      const fav: FavoriteContract = {
        securityName: getSecurity(details),
        details: {
          ...details,
          underSymbol: details.underSymbol || '',
          underSecType: details.underSecType || '',
          contractMonth: details.contractMonth || '',
          lastTradeTime: details.lastTradeTime || '',
          realExpirationDate: details.realExpirationDate || '',
          timeZoneId: details.timeZoneId || '',
          liquidHours: details.liquidHours || '',
          tradingHours: details.tradingHours || '',
          contract: {
            ...details.contract,
            multiplier: details.contract.multiplier || '',
            lastTradeDateOrContractMonth: details.contract.lastTradeDateOrContractMonth || ''
          }
        }
      };
      dispatch(addFavoriteAsync(fav));
    }
  };

  return [isFavorite, toggleFavorite];
};

export default useToggleFavorite;
